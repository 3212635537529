import erpnextApi from "@/rest/ErpnextApi.ts";
import { formatDateToERP } from "@/utils/Helper.ts";
import { calculateDurationBetweenDates, handleAbsenceSubmission, isDateRangeValid } from "@/features/absence/utils/Absence.ts";
import moment from "moment/moment";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService.ts";
import { reactive, ref } from "vue";
import { FileUploadSelectEvent } from "primevue/fileupload";
import { helpers, maxLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { getCurrentEmployeeName } from "@/utils/GetEmployeeName.ts";
import { IllnessFormEmits, IllnessFormProps } from "@/features/absence/components/illnessForm/IllnessForm.vue";
import { useStore } from "vuex";

interface Illness {
  dateRange: (Date | undefined)[];
  reason: string;
}

export function useIllnessForm(props: IllnessFormProps, emit: IllnessFormEmits) {
  const submitted = ref<boolean>(false);
  const isLoading = ref<boolean>(false);

  const illness = reactive<Illness>({
    dateRange: props.startAbsence && props.endAbsence ? [props.startAbsence, props.endAbsence] : [],
    reason: "",
  });

  const toast = useToast();
  const store = useStore();
  let file: File;
  const fileSelected = ref<string>("");
  const isFileUploading = ref<boolean>(false);

  const illnessRules = {
    dateRange: { required: helpers.withMessage("Bitte einen Zeitraum angeben", required) },
    reason: { maxLengthValue: helpers.withMessage("Bitte nutzen Sie maximum 100 Zeichen.", maxLength(100)) },
  };

  const validation = useVuelidate(illnessRules, illness);

  function onFileSelect(event: FileUploadSelectEvent) {
    if (!event.files || !Array.isArray(event.files) || !event.files.length) return;
    file = event.files[0];
    fileSelected.value = file.name;
  }

  function resetIllnessValues() {
    illness.dateRange = [];
    illness.reason = "";
  }

  function clearFileSelect() {
    fileSelected.value = "";
  }

  async function attachReport(sicknessApplianceName: string, startDate: Date): Promise<void> {
    if (!sicknessApplianceName) return;
    isFileUploading.value = true;

    const formData = new FormData();
    formData.append("file", file, "Krankmeldung_" + moment(startDate).format("YYYYMMDD") + "_" + getCurrentEmployeeName(store) + ".pdf");
    formData.append("doctype", "Leave Application");
    formData.append("docname", sicknessApplianceName);
    formData.append("is_private", "1");

    try {
      await erpnextApi.postIllnessFile(formData);
      showSuccessToast(toast, "Datei erfolgreich hochgeladen.");
    } catch (err: any) {
      showErrorToast(toast, err.message);
    } finally {
      isFileUploading.value = false;
    }
  }

  function finalizeIllnessRequestSubmission() {
    isLoading.value = false;
    clearFileSelect();
  }

  function handleSuccessfulIllnessFormSubmission() {
    showSuccessToast(toast, "Krankmeldung erfolgreich eingereicht");
    resetIllnessValues();
    emit("submitted");
  }

  async function submitIllnessForm(isFormValid: boolean): Promise<void> {
    submitted.value = true;
    if (!isFormValid) return;
    isLoading.value = true;

    if (!isDateRangeValid(illness.dateRange)) {
      isLoading.value = false;
      return;
    }

    try {
      await handleAbsenceSubmission(illness.dateRange, submitIllnessRequest);
      handleSuccessfulIllnessFormSubmission();
      submitted.value = false;
    } catch (err: any) {
      handleSubmissionError(err);
    } finally {
      finalizeIllnessRequestSubmission();
    }
  }

  function handleSubmissionError(error: unknown): void {
    const errorMessage = (error as Error)?.message || "Ein unbekannter Fehler ist aufgetreten";
    showErrorToast(toast, errorMessage);
  }

  async function submitIllnessRequest(startDate: Date, endDate: Date) {
    const sicknessApplianceName = await erpnextApi.postSickRequest(
      formatDateToERP(startDate),
      calculateDurationBetweenDates(startDate, endDate),
      illness.reason,
    );

    if (!fileSelected.value) return;
    await attachReport(sicknessApplianceName, startDate);
  }

  return {
    clearFileSelect,
    fileSelected,
    isFileUploading,
    validation,
    onFileSelect,
    submitIllnessForm,
    isLoading,
    submitted,
  };
}
