import { Desk, DeskState, Employee, Note, WorkplaceBooking } from "@/data-types";
import moment from "moment";

export function employeesNoteIfPresent(notes: Note[], employee: string): string {
  const note = notes.find((note) => {
    return note.employee === employee;
  });
  return note ? note.note : "";
}

export function getStartOfWeekFromWeek(week = moment().week(), year = moment().year()): moment.Moment {
  const today = moment();
  const currentStartOfWeek = today.clone().add(2, "days").startOf("isoWeek");

  if (!week) return currentStartOfWeek;

  const requestedStartOfWeek = moment().year(year).week(week).add(2, "days").startOf("isoWeek");
  const maxAllowedStartOfWeek = today.clone().add(1, "week").startOf("isoWeek");

  return requestedStartOfWeek.isSameOrBefore(maxAllowedStartOfWeek) ? requestedStartOfWeek : currentStartOfWeek;
}

export function getProgressBarClass(disabled: boolean, bookedDesks: number): any {
  switch (bookedDesks) {
    case 1:
      return { redBar: true, "p-disabled": disabled };
    case 2:
      return { yellowBar: true, "p-disabled": disabled };
    default:
      return { "p-disabled": disabled };
  }
}

export function getWorkplaceStatusMessage(booking: WorkplaceBooking): string {
  const myselfAtOffice = booking.workplace === "office";
  let amountOfEmployeesInOffice = booking.others_at_office.length;
  if (myselfAtOffice) amountOfEmployeesInOffice += 1;
  return `${amountOfEmployeesInOffice} Mitarbeiter:${amountOfEmployeesInOffice > 1 ? "innen" : "in"} im Büro`;
}

export function getDeskTooltip(desks: Map<string, Desk>, deskState: DeskState, employees: Map<string, Employee>): string {
  return [getDeskStatusTextForTooltip(deskState, employees), getDeskNotes(desks, deskState), getDeskStateBlockingReason(deskState)].filter(Boolean).join("\n");
}

function getDeskStateBlockingReason(deskState: DeskState): string {
  return deskState.reason || "";
}

function getDeskStatusTextForTooltip(deskState: DeskState, employees: Map<string, Employee>): string {
  if (deskState.available) {
    return "";
  } else {
    return employees.get(deskState.blocked_by)?.full_name || "belegt";
  }
}

function getDeskNotes(desks: Map<string, Desk>, deskState: DeskState) {
  return desks.get(deskState.name)?.notes || "";
}

export function getDeskStatusText(deskState: DeskState, employees: Map<string, Employee>): string {
  if (deskState.available) {
    return "";
  }
  const employeeAbbreviation = employees.get(deskState.blocked_by)?.abbreviation;
  return employeeAbbreviation ? `(${employeeAbbreviation})` : "belegt";
}

export function getDeskTitle(deskState: DeskState, desks: Map<string, Desk>) {
  const _desk = desks.get(deskState.name);
  return _desk !== undefined && _desk.title;
}

export function getWorkplaceTable(booking: WorkplaceBooking, desks: Map<string, Desk>) {
  if (booking.desk) return desks.get(booking.desk)?.title;
  else return "Kein Arbeitsplatz";
}

export function isNoteAddable(dayDisabled: boolean, currentBooking: WorkplaceBooking, employee: string) {
  if (dayDisabled || !currentBooking.workplace) return false;
  return !currentBooking.notes.find((note) => {
    return note.employee === employee;
  });
}
