<template>
  <div class="p-mt-4">
    <Chart v-if="chartData.datasets.length !== 0" ref="primeChart" type="bar" :data="chartData" :options="chartOptions" :height="chartHeight" />
    <div v-else>Keine Buchungspositionen gefunden</div>
  </div>
</template>

<script setup lang="ts">
import { inject, readonly, ref } from "vue";
import { emptyYearReport } from "@/data-types.ts";
import { yearReportKey } from "@/keys";
import Chart from "primevue/chart";
import { chartOptions, createMonthStringArray } from "@/features/yearly/utils/Yearly";
import { colorFromString } from "@/utils/ColorFromString";

const primeChart = ref();
const yearReportInjected = inject(yearReportKey, {
  yearReport: readonly(ref(emptyYearReport)),
  addBookingPosition: () => undefined,
});
const yearReport = yearReportInjected.yearReport.value;
const chartHeight = window.innerWidth >= 576 ? 140 : 300;

const chartData = {
  labels: createMonthStringArray(),
  datasets: yearReport.booking_positions
    .filter(({ name }) => name !== "Summe")
    .map(({ months, name, project }) => ({
      data: [...months],
      label: name,
      backgroundColor: colorFromString(`${name}-${project}`),
    })),
};
</script>
