<template>
  <div class="widgetBox">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.widgetBox {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: 2rem;
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--dark);
}

@media (max-width: 700px) {
  .widgetBox {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    padding: 0.5rem;
  }
}
</style>
