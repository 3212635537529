<template>
  <Button v-tooltip="'Übrige Zeit hinzufügen'" icon="pi pi-plus-circle" class="time-button-project" @click="emit('click')"></Button>
</template>

<script setup lang="ts">
import Button from "primevue/button";

const emit = defineEmits<{
  click: [void];
}>();
</script>

<style scoped></style>
