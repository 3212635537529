<template>
  <div class="weeklyViewport">
    <WeekControl
      id="weekControl"
      title="Zeiterfassung"
      :year="erpWeek[0]?.date?.getFullYear()"
      :week="calendarWeek"
      :disable-previous-week="disablePreviousWeekButton"
      :disable-next-week="disableNextWeekButton"
      :is-swipe-deactivated="isSwipeDeactivated"
      @change-week-backward="changeWeekBackward"
      @change-week-forward="changeWeekForward"
      @change-week="changeWeek"
      @touchstart.passive="activateSwipe"
    ></WeekControl>
    <div class="flexBoxingWeekly">
      <weekly-table
        v-if="loadedContent"
        :days="erpWeek"
        :booking-positions="bookingPositionDescriptionByName"
        :booking-data="bookingDayByDayHash"
        @touchstart.passive="deactivateSwipe"
      ></weekly-table>

      <ProgressSpinner v-if="!loadedContent" class="p-d-flex p-jc-center" />
      <div class="flexBoxingWeeklyBudget">
        <left-budget
          v-if="loadedContent && !isExternal"
          class="p-mt-0"
          :days="currentMonthData"
          :booking-positions="bookingPositionDescriptionByName"
          @touchstart.passive="activateSwipe"
        >
        </left-budget>
        <monthly-total
          v-if="loadedContent"
          class="p-mt-0"
          :booking-positions="bookingPositionDescriptionByName"
          :month-data="currentMonthData"
          @touchstart.passive="activateSwipe"
        ></monthly-total>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AnnualHours, BookingDay } from "@/data-types";
import LeftBudget from "@/features/weekly/components/LeftBudget.vue";
import MonthlyTotal from "@/features/weekly/components/MonthlyTotal.vue";
import WeeklyTable from "@/features/weekly/components/WeeklyTable.vue";
import { calculateDayHash } from "@/features/weekly/utils/Weekly";
import { week } from "@/keys";
import erpnextApi from "@/rest/ErpnextApi.ts";
import store from "@/store";
import WeekControl from "@/ui/WeekControl.vue";
import moment from "moment";
import ProgressSpinner from "primevue/progressspinner";
import { computed, onMounted, provide, reactive, Ref, ref } from "vue";
import { useLoadBookingDataForPeriod } from "@/features/weekly/utils/useLoading.ts";

const today = moment();
const currentMonthData = reactive([] as BookingDay[]);
const calendarWeek = ref();
provide(week, calendarWeek);
const annualStatement = ref<AnnualHours>();
const erpWeek = ref<BookingDay[]>([]);
const changeWeekMultiplier = ref(0);
const loadedContent = ref(false);
const disableNextWeekButton = ref(true);
const disablePreviousWeekButton = ref(true);
const isSwipeDeactivated = ref(false);
const isExternal = computed(() => store.getters.isExternal);
store.dispatch("fetchTransportationProfiles");

const { loadBookingDataForPeriod, bookingPositionDescriptionByName, bookingDayByDayHash } = useLoadBookingDataForPeriod();

async function loadAnnualStatement() {
  annualStatement.value = await erpnextApi.getAnnualHours(today.year());
}

function deactivateSwipe() {
  isSwipeDeactivated.value = true;
}

function activateSwipe() {
  isSwipeDeactivated.value = false;
}

async function findMondayThreeWeeksAgo(): Promise<Date> {
  const currentMonday = today.toDate();
  let offset = currentMonday.getDay() - 1;
  if (offset === -1) offset = 6;
  currentMonday.setDate(currentMonday.getDate() - offset - 14);
  return currentMonday;
}

async function initializeCurrentWeek() {
  const mondayThreeWeeksAgo = await findMondayThreeWeeksAgo();
  await loadBookingDataForPeriod(mondayThreeWeeksAgo, 21);
  await loadAnnualStatement();

  // No await, as we do not want to wait for this update-call
  // noinspection ES6MissingAwait
  updateData();
}

const addBookingPositionsFromLastWeeks = () => {
  const currentWeek = erpWeek.value[0]?.date;
  if (!currentWeek) return;
  const twoWeeksAgo = new Date(currentWeek);
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
  const endOfTheWeek = new Date(erpWeek.value[erpWeek.value.length - 1].date!);

  const entriesLastTwoWeeks = [...bookingDayByDayHash.value.values()].filter((x: BookingDay) => x.date && x.date >= twoWeeksAgo && x.date < currentWeek);
  const entriesThisWeek = [...bookingDayByDayHash.value.values()].filter((x: BookingDay) => x.date && x.date >= currentWeek && x.date <= endOfTheWeek);
  const usedBookingPositionsDescriptionNames: string[] = [
    ...new Set(
      entriesLastTwoWeeks
        .flatMap((x: BookingDay) => x.positionHours)
        .filter((x) => x.hours !== "00:00")
        .map((x) => x.name),
    ),
  ];

  const bPsToKeep = new Set();
  const bPsToKeep2 = new Set();

  for (const bp of usedBookingPositionsDescriptionNames) {
    for (const entry of entriesThisWeek) {
      if (!entry.positionHours.find((x) => x.name === bp)) {
        entry.positionHours.push({
          name: bp,
          hours: "00:00",
          comment: "",
        });
        bPsToKeep2.add(bp);
      }
    }
  }

  for (const entry of entriesThisWeek) {
    entry.positionHours = entry.positionHours.filter((x) => {
      const bPosition = bookingPositionDescriptionByName.value.get(x.name);
      if (bPosition === undefined) {
        return true;
      }
      if (bPosition?.changeable_till === null) {
        return true;
      }
      return erpWeek.value[0].date! <= new Date(bPosition?.changeable_till);
    });
    for (const positionHour of entry.positionHours) {
      const bPosition = bookingPositionDescriptionByName.value.get(positionHour.name);
      if (positionHour.hours !== "00:00" || !bPosition?.closed) {
        bPsToKeep.add(positionHour.name);
      } else {
        bPsToKeep2.delete(positionHour.name);
      }
    }
  }

  for (const entry of entriesThisWeek) {
    entry.positionHours = entry.positionHours.filter((x) => bPsToKeep.has(x.name) || bPsToKeep2.has(x.name));
  }
  loadedContent.value = true;
};

function calculateFirstWeekday() {
  const currDate = new Date();
  const firstWeekDay = new Date();
  firstWeekDay.setHours(0, 0, 0, 0);
  let offset = currDate.getDay() - 1;
  if (offset == -1) offset = 6;
  firstWeekDay.setDate(firstWeekDay.getDate() + changeWeekMultiplier.value * 7 - offset);
  return firstWeekDay;
}

function UpdateErpNextWeekProperties(combinedMonthlyEntries: Ref) {
  erpWeek.value = [];
  const firstWeekDay = calculateFirstWeekday();

  const lastWeekDay = new Date(firstWeekDay);
  lastWeekDay.setDate(lastWeekDay.getDate() + 7);
  combinedMonthlyEntries.value.forEach((entry: BookingDay) => {
    if (entry.date && entry.date >= firstWeekDay && entry.date < lastWeekDay) erpWeek.value.push(entry);
  });

  calendarWeek.value = moment(firstWeekDay).week();
}

/**
 * This method is used to load the booking data of the current month
 * and the week before and after the current month
 *
 * @param currentDate of today
 */
async function loadSurroundingWeeks(currentDate: Date) {
  const startOfCurrentWeek = moment(currentDate);

  /* First week of the month */
  const startOfFirstWeek = moment(currentDate);
  startOfFirstWeek.startOf("month");
  startOfFirstWeek.startOf("isoWeek");

  /* Last week of the month */
  const startOfLastWeek = moment(currentDate);
  startOfLastWeek.endOf("month");
  startOfLastWeek.startOf("isoWeek");

  if (startOfFirstWeek.isSame(startOfCurrentWeek, "week")) startOfFirstWeek.subtract(1, "week");
  if (startOfLastWeek.isSame(startOfCurrentWeek, "week")) startOfLastWeek.add(1, "week");

  const startOfInterestWeek = startOfFirstWeek.clone();
  let loadMissingWeekSince = null;
  while (startOfInterestWeek.isSameOrBefore(startOfLastWeek, "week")) {
    if (!bookingDayByDayHash.value.has(calculateDayHash(startOfInterestWeek.toDate()))) {
      if (loadMissingWeekSince == null) {
        loadMissingWeekSince = startOfInterestWeek.clone();
      }
    } else {
      if (loadMissingWeekSince != null) {
        const days = startOfInterestWeek.diff(loadMissingWeekSince, "days");
        await loadBookingDataForPeriod(loadMissingWeekSince.toDate(), days + 7);
        loadMissingWeekSince = null;
      }
    }
    startOfInterestWeek.add(1, "week");
  }
  if (loadMissingWeekSince != null) {
    const days = startOfLastWeek.diff(loadMissingWeekSince, "days");
    await loadBookingDataForPeriod(loadMissingWeekSince.toDate(), days + 7);
  }
}

function updatePrevNextButtons(currentDate: Date) {
  const lastWeek = moment(currentDate);
  const nextWeek = moment(currentDate);
  lastWeek.subtract(1, "week");
  nextWeek.add(1, "week");
  disablePreviousWeekButton.value = !bookingDayByDayHash.value.has(calculateDayHash(lastWeek.toDate()));
  disableNextWeekButton.value = !bookingDayByDayHash.value.has(calculateDayHash(nextWeek.toDate()));
}

async function updateMonthData() {
  loadedContent.value = false;
  const currentDate = erpWeek.value[0]?.date;
  if (!currentDate) return;
  disablePreviousWeekButton.value = disableNextWeekButton.value = true;
  await loadSurroundingWeeks(currentDate);
  updatePrevNextButtons(currentDate);
  if (currentMonthData.length > 0 && currentMonthData[0].date && currentMonthData[0].date.getMonth() != currentDate.getMonth()) currentMonthData.length = 0;
  updatePrevNextButtons(currentDate);

  if (currentMonthData.length == 0) {
    for (const day of bookingDayByDayHash.value.values()) {
      if (day.date && day.date.getMonth() == currentDate.getMonth() && day.date.getFullYear() == currentDate.getFullYear()) {
        currentMonthData.push(day);
      }
    }
  }
}

const changeWeekForward = async () => {
  changeWeekMultiplier.value++;
  await updateData();
};

const changeWeekBackward = async () => {
  changeWeekMultiplier.value--;
  await updateData();
};

const changeWeek = async (date: Date | undefined) => {
  if (!date) return;
  const currentWeekStart = calculateFirstWeekday();
  const Difference_In_Time = date.getTime() - currentWeekStart.getTime();
  const Difference_In_Weeks = Math.trunc(Difference_In_Time / (1000 * 3600 * 24 * 7));
  changeWeekMultiplier.value += Difference_In_Weeks;
  await loadBookingDataForPeriod(calculateFirstWeekday(), 21);
  await updateData();
};

async function updateData() {
  erpnextApi.limitWB.clearQueue();
  UpdateErpNextWeekProperties(bookingDayByDayHash);
  await updateMonthData();
  addBookingPositionsFromLastWeeks();
  UpdateErpNextWeekProperties(bookingDayByDayHash);
}

onMounted(async () => {
  await initializeCurrentWeek();
});
</script>
