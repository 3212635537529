<template>
  <Tag v-if="publicHoliday" class="color">
    <div style="padding: 0.1rem" class="p-button-sm">
      {{ publicHoliday }}
    </div>
  </Tag>
</template>

<script setup lang="ts">
import Tag from "primevue/tag";

interface Props {
  publicHoliday: string;
}

defineProps<Props>();
</script>

<style scoped>
.color {
  background-color: var(--pink);
  color: var(--white);
}
</style>
