<template>
  <div v-if="payouts.length > 0" class="p-pt-2">
    <span class="p-text-bold">Auszahlungen und Gutschriften:</span>
    <DataTable :value="payouts" responsive-layout="scroll" class="p-datatable-sm" striped-rows>
      <Column field="datum" header="Datum" />
      <Column field="stunden" header="Stunden" />
      <Column field="betrag" header="Betrag" />
      <Column field="kommentar" header="Kommentar" />
    </DataTable>
  </div>
  <div v-if="workPlans.length > 0" class="p-pt-4">
    <span class="p-text-bold">Umfänge aus Arbeitsplänen:</span>
    <DataTable :value="workPlans" responsive-layout="scroll" class="p-datatable-sm" striped-rows>
      <Column field="datumVon" header="Von" />
      <Column field="datumBis" header="Bis" />
      <Column field="stunden" header="Stunden" />
      <Column field="umfang" header="Umfang (in %)" />
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { annualStatementKey } from "@/keys";
import { emptyAnnualStatement } from "@/data-types";
import { convertFromErpDate } from "@/utils/Helper";

const annualStatementInjected = inject(annualStatementKey, ref(emptyAnnualStatement));
const yearlyAnnualStatement = computed(() => {
  return annualStatementInjected.value;
});

const workPlans = computed(() =>
  yearlyAnnualStatement.value.mandatory_details.map((detail) => ({
    datumVon: convertFromErpDate(detail.start_date),
    datumBis: convertFromErpDate(detail.end_date),
    stunden: detail.hours.toFixed(2),
    umfang: `${detail.percent}%`,
  })),
);

const payouts = computed(() =>
  yearlyAnnualStatement.value.payouts.map((detail) => ({
    datum: convertFromErpDate(detail.date),
    stunden: detail.hours,
    betrag: detail.amount + " €",
    kommentar: detail.comment,
  })),
);
</script>

<style scoped>
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 1rem 0;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border: 0;
}
</style>
