<template>
  <div class="p-mt-5">
    <ProfileForm :is-changed="hasChanges" @save="save(!v$.$invalid)">
      <div class="profile-form-edit-container">
        <ProfileFormField
          label="Standard Verkehrsmittel"
          type="dropdown"
          width="4"
          identifier="default_transportation"
          :options="transportations"
          :submitted="submitted"
          :is-changed="hasChanges"
        />
        <ProfileFormField
          width="6"
          label="Benachrichtigung an private E-Mail senden"
          type="switch"
          identifier="parking_reservation_to_personal_mail"
          :submitted="submitted"
          :is-changed="hasChanges"
        />
      </div>
    </ProfileForm>

    <ProfileFormHeader class="p-mt-5" label="Profile" />
    <DataTable scrollable :value="transportations" class="visitor-table" responsive-layout="true">
      <Column field="icon" header="Bild" />
      <Column field="title" header="Titel" />
      <Column v-if="!isMobile()" field="primary_vehicle" header="Verkehrsmittel">
        <template #body="slotProps">
          <span>{{ transportationTypes.find((x) => x.id === slotProps.data.primary_vehicle)?.title || "" }}</span>
        </template>
      </Column>
      <Column v-if="!isMobile()" field="co2_consumption" header="CO2 (kg)" />
      <Column v-if="!isMobile()" field="distance" header="Strecke (km)" />
      <Column field="name" header="">
        <template #header>
          <Button class="transport-button" icon="pi pi-plus" @click="addProfile" />
        </template>
        <template #body="slotProps">
          <Button class="transport-button" icon="pi pi-pencil" @click="editProfile(slotProps.data)" />
          <Button class="transport-button" icon="pi pi-trash" @click="askToDeleteProfile(slotProps.data)" />
        </template>
      </Column>
    </DataTable>
    <Dialog
      v-model:visible="displayDialog"
      :header="selectedProfile?.name?.length == 0 ? 'Profil hinzufügen' : 'Profil ändern'"
      :dismissable-mask="false"
      :modal="true"
      :style="{ minWidth: '25vw', maxWidth: '400px' }"
    >
      <div class="flex flex-column gap-2">
        <label class="color-white" for="icon">Bild</label>
        <Dropdown
          id="icon"
          v-model="selectedProfile.icon"
          :options="['👟', '🏃', '👞', '👠', '🚃', '🚌', '🚂', '🚈', '🚴', '🚲', '🚗', '🚘', '🏎️', '🚕', '🛺', '🏍️', '🛵', '🛴', '🚀']"
          class="full-width profileIconDropDown"
        />
      </div>
      <div class="flex flex-column gap-2">
        <label class="color-white" for="titel">Titel</label>
        <InputText id="titel" v-model="selectedProfile.title" class="full-width" />
      </div>
      <div class="flex flex-column gap-2">
        <label class="color-white" for="titel">Hauptverkehrsmittel</label>
        <Dropdown
          id="icon"
          v-model="selectedProfile.primary_vehicle"
          :options="transportationTypes"
          option-label="title"
          option-value="id"
          class="full-width"
        />
      </div>
      <div class="flex flex-column gap-2">
        <label class="color-white" for="titel">CO2-Verbrauch</label>
        <InputNumber id="titel" v-model="selectedProfile.co2_consumption" :min-fraction-digits="3" :max-fraction-digits="3" suffix=" kg" :min="0" />
      </div>

      <div class="flex flex-column gap-2">
        <label class="color-white" for="titel">Gesamtstrecke</label>
        <InputNumber id="titel" v-model="selectedProfile.distance" locale="de-DE" :min-fraction-digits="3" :max-fraction-digits="3" suffix=" km" :min="0" />
      </div>

      <div v-if="selectedProfile?.primary_vehicle === 'car' || selectedProfile?.primary_vehicle === 'ecar'" class="flex flex-column gap-2">
        <Checkbox v-model="selectedProfile.parking_lot_desired" class="lot-cb" input-id="parking_lot_desired" :binary="true" />
        <label class="color-white" for="parking_lot_desired">Parkplatz gewünscht</label>
      </div>

      <div class="flex flex-column gap-2 p-mt-2">
        <a target="_blank" href="https://pep-digital.atlassian.net/wiki/spaces/PEPDIGITAL/pages/2189426736/Verkehrsmittel+Profile+bearbeiten"
          >Informationen zum Anlegen von Profilen und der Berechnung von CO₂-Verbräuchen.</a
        >
      </div>
      <div>
        <Button
          :label="selectedProfile?.name?.length == 0 ? 'Hinzufügen' : 'Aktualisieren'"
          icon="pi pi-check"
          class="p-mt-4"
          style="float: right"
          @click="saveSelectedProfile"
        />
      </div>
    </Dialog>
    <base-pop-up v-if="openConfirmValidation" :pop-up-message="`Profil ${selectedProfile.title} löschen?`">
      <Button label="Ja!" style="margin-right: 1rem" @click="deleteProfile()" />
      <Button label="Nein!" @click="openConfirmValidation = false" />
    </base-pop-up>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, provide, ref } from "vue";
import ProfileForm from "@/features/profile/components/ProfileForm.vue";
import ProfileFormField from "@/features/profile/components/ProfileFormField.vue";
import { loading, validate } from "@/keys";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { isMobile } from "@/utils/Helper";
import { useToast } from "primevue/usetoast";
import Dialog from "primevue/dialog";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { erpNextTransportationProfile, erpNextUserProfile } from "@/data-types";
import clonedeep from "lodash.clonedeep";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProfileFormHeader from "@/features/profile/components/ProfileFormHeader.vue";
import BasePopUp from "@/ui/BasePopUp.vue";
import InputNumber from "primevue/inputnumber";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService.ts";
import { initDefaultErpNextUserProfile } from "@/features/profile/utils/Profile";

const store = useStore();
const toast = useToast();
const submitted = ref(false);
const isLoading = inject(loading, ref(true));
const displayDialog = ref(false);
const openConfirmValidation = ref(false);
const selectedProfile = ref<erpNextTransportationProfile>({} as erpNextTransportationProfile);
const userProfile = ref<erpNextUserProfile>(initDefaultErpNextUserProfile());

isLoading.value = true;

(async () => {
  try {
    // then() and catch() are not working in dispatch, so we use await
    await store.dispatch("fetchTransportationProfiles");
    userProfile.value = clonedeep(store.getters.getUserProfile);
  } catch (err) {
    showErrorToast(toast, `${err}`);
  } finally {
    isLoading.value = false;
  }
})();
const rules = {
  show_birthdays: {},
  show_absences: {},
  show_presences: {},
  calculate_end_of_working_day: {},
  default_transportation: {},
  parking_reservation_to_personal_mail: {},
};

const v$ = useVuelidate(rules, userProfile);
provide(validate, v$);

const initialState = ref(clonedeep(userProfile.value));
const hasChanges = computed(() => {
  return JSON.stringify(userProfile.value) !== JSON.stringify(initialState.value);
});

function save(isFormValid: boolean) {
  submitted.value = true;
  if (!isFormValid) {
    return;
  }
  isLoading.value = true;
  erpnextApi
    .updateProfileSettings(userProfile.value)
    .then(() => {
      showSuccessToast(toast, erpnextApi.ProfileUpdatedMessage);
      store.commit("setUserProfile", userProfile.value);
    })
    .catch((err) => showErrorToast(toast, err))
    .finally(() => (isLoading.value = false));
  initialState.value = clonedeep(userProfile.value);
}

function addProfile() {
  selectedProfile.value = {
    name: "",
    co2_consumption: 0,
    distance: 0,
    icon: "👟",
    primary_vehicle: "foot",
    title: "",
    parking_lot_desired: false,
  };
  displayDialog.value = true;
}

function editProfile(entry: erpNextTransportationProfile) {
  selectedProfile.value = clonedeep(entry);
  displayDialog.value = true;
}

function askToDeleteProfile(entry: erpNextTransportationProfile) {
  selectedProfile.value = entry;
  openConfirmValidation.value = true;
}

function deleteProfile() {
  openConfirmValidation.value = false;
  if (!selectedProfile.value) return;
  isLoading.value = true;
  erpnextApi
    .deleteTransportationProfile(selectedProfile.value.name)
    .then(() => {
      showSuccessToast(toast, erpnextApi.ProfileUpdatedMessage);
      store.dispatch("reloadTransportationProfiles");
    })
    .catch((err) => showErrorToast(toast, err))
    .finally(() => (isLoading.value = false));
}

function saveSelectedProfile() {
  if (!selectedProfile.value) return;

  if (selectedProfile.value?.name) {
    erpnextApi
      .updateTransportationProfile(selectedProfile.value)
      .then(() => {
        showSuccessToast(toast, erpnextApi.ProfileUpdatedMessage);
        store.dispatch("reloadTransportationProfiles");
      })
      .catch((err) => showErrorToast(toast, err))
      .finally(() => (displayDialog.value = false));
  } else {
    erpnextApi
      .addTransportationProfile(selectedProfile.value)
      .then(() => {
        showSuccessToast(toast, erpnextApi.ProfileUpdatedMessage);
        store.dispatch("reloadTransportationProfiles");
      })
      .catch((err) => showErrorToast(toast, err))
      .finally(() => (displayDialog.value = false));
  }
}

const transportations = computed(() => store.getters.getTransportationProfiles);
const transportationTypes = [
  { id: "foot", title: "Zu Fuß" },
  { id: "bike", title: "Fahrrad" },
  { id: "ebike", title: "E-Bike" },
  { id: "transit", title: "ÖPNV" },
  { id: "car", title: "Auto" },
  { id: "ecar", title: "E-Auto" },
  { id: "motorcycle", title: "Motorrad" },
  { id: "scooter", title: "Roller" },
];
</script>

<style scoped>
.transport-button.p-button.p-button-icon-only {
  width: 24px;
  height: 24px;
  margin: 4px;
}

.lot-cb {
  margin-right: 4px;
  margin-top: 8px;
}

.profile-form-edit-container {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
}
</style>
<style>
.profileIconDropDown .p-dropdown-label {
  font-size: 32px;
}
</style>
