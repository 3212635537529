<template>
  <BasePopUp v-if="showPopUp" :pop-up-message="popUpMessage">
    <Button @click="hidePopUp()">Okay</Button>
  </BasePopUp>
  <Dialog v-model:visible="workplaceEditorVisible" :header="'Arbeitsort ändern für ' + day.currentDate" :style="{ minWidth: '25vw' }" modal>
    <div style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap">
      <SelectButton
        v-model="currentWorkplace"
        :options="workplaces.filter((workplace) => workplace.code !== 'absent')"
        :unselectable="false"
        class="workplace-select"
        option-label="name"
        option-value="code"
        @change="submitWorkplaceBooking()"
      />
      <Dropdown
        v-if="currentWorkplace === 'office' && !isExternal"
        v-model="currentTransportationProfile"
        :options="transportations"
        :unselectable="false"
        class="transportation-select"
        option-label="title"
        option-value="name"
        panel-class="transportation-dropdown"
        @change="submitWorkplaceBooking()"
      >
        <template #value="slotProps">
          <span style="font-size: 28px">{{ transportations.find((x: any) => x.name === slotProps.value)?.icon ?? "⁉️" }}</span>
        </template>
      </Dropdown>
    </div>
  </Dialog>
  <div v-if="showTrashcan" style="position: absolute; right: 2px">
    <Button
      v-tooltip="'Eingetragene Zeiten löschen'"
      :pt="trashCanButtonProps"
      icon="pi pi-trash"
      outlined
      severity="danger"
      aria-label="Reset Day"
      @click.prevent="resetDay"
    />
  </div>
  <div>
    <div class="headerBox">
      <ProgressSpinner v-if="waitForPush" class="p-d-flex p-jc-center" style="width: 80px; bottom: 30px" />
      <div v-if="!waitForPush" :style="[isUnsavedChanges ? 'color: #01579b;' : 'border: none;']">
        {{ getWorkdayForDate(new Date(day.currentDate)) }}
        <br />
        {{ formatDateWithoutYear(new Date(day.currentDate)) }}
        <p style="font-size: 10px; margin-top: 0">
          <TooltipWrapper v-if="workplaceDescription" content="Arbeitsort ändern">
            <div
              :class="{ workplaceInvalid: workplaceDescription === 'Nicht angegeben', workplaceValid: workplaceDescription }"
              style="text-decoration: none; padding-bottom: 3px"
              @click="wantToChangeWorkplace"
            >
              {{ workplaceDescription }}
              <span v-if="canChangeWorkplace">✏️️</span>
            </div>
          </TooltipWrapper>
          <span v-if="isDayInWeekend" class="weekend-holiday">{{ day.publicHoliday }}</span>
          <router-link v-else-if="day.publicHoliday" :to="workplaceRoute" class="weekend-holiday">
            {{ day.publicHoliday }}
          </router-link>
        </p>
      </div>
    </div>
    <div
      v-if="day.fixed"
      :class="{
        isHoliday: day.publicHoliday,
      }"
      style="display: flex; justify-content: center; height: 100%; border-top: 0.1rem solid var(--borderColor); padding: 1rem"
    >
      <div style="writing-mode: vertical-rl; text-orientation: mixed; color: var(--fontColorGrey); font-weight: bolder">
        <p v-if="day.publicHoliday">{{ day.publicHoliday }}</p>
        <p v-else>{{ day.fixed }}</p>
      </div>
    </div>
    <div v-else :class="{ isHoliday: day.publicHoliday }">
      <!--Start-->
      <div class="box">
        <DesktopTimePicker
          v-if="!isMobile()"
          v-model="day.start"
          :autofocus="isCurrentDay"
          :can-clear="true"
          :disabled="isDisabled"
          class="startTimeTest"
          @clear="checkForClear"
          @update:model-value="onChanged"
        />
        <TimePicker
          v-if="isMobile()"
          v-model="day.start"
          :autofocus="isCurrentDay"
          :can-clear="true"
          :disabled="isDisabled"
          class="startTimeTest"
          @clear="checkForClear"
          @update:model-value="onChanged"
        />
      </div>
      <div v-if="day.start !== ''" class="p-d-flex p-flex-column">
        <!--Break-->
        <div class="box" style="position: relative">
          <IncreaseBookingPosition v-if="isTimeToBookLeft" @click="adjustBreakTime()" />
          <DecreaseBookingPosition
            v-if="isDecreaseBookingPositionShownForBreak(isOverbookedTime, day.end) && isMinimumBreakTimeTaken(rules, day, endTimeDiff)"
            @click="adjustBreakTime()"
          />
          <DesktopTimePicker
            v-if="!isMobile()"
            v-model="day.break"
            :class="{ 'p-invalid': v$.break.$errors.length }"
            :disabled="isDisabled"
            @update:model-value="onChanged"
          />
          <TimePicker
            v-if="isMobile()"
            v-model="day.break"
            :class="{ 'p-invalid': v$.break.$errors.length }"
            :disabled="isDisabled"
            @update:model-value="onChanged"
          />

          <span v-if="v$.break.$errors.length" class="hint-msg p-error">
            {{ v$.break.$errors[0].$message }}
          </span>
        </div>

        <!--End-->
        <div class="box">
          <DesktopTimePicker
            v-if="!isMobile()"
            v-model="day.end"
            :class="{ 'p-invalid': v$.end.$errors.length }"
            :disabled="isDisabled"
            class="testEndField"
            @update:model-value="onEndTimeChanged"
          />
          <TimePicker
            v-if="isMobile()"
            v-model="day.end"
            :class="{ 'p-invalid': v$.end.$errors.length }"
            :disabled="isDisabled"
            class="testEndField"
            @update:model-value="onEndTimeChanged"
          />
          <span v-if="v$.end.$errors.length" class="hint-msg p-error">
            {{ v$.end.$errors[0].$message }}
          </span>
        </div>
        <!--WorkTime-->
        <div class="box">
          <DesktopTimePicker v-if="!isMobile()" v-model="workTime" :disabled="true" />
          <TimePicker v-if="isMobile()" v-model="workTime" :disabled="true" />
        </div>
        <!--ProjectTime-->
        <div
          v-for="(bookingDayPosition, index) in day.positionHours"
          :key="bookingDayPosition.name"
          @mouseenter="isHoveringOverBookingDayPosition = bookingDayPosition.name"
          @mouseover="isHoveringOverBookingDayPosition = bookingDayPosition.name"
          @mouseleave="isHoveringOverBookingDayPosition = undefined"
        >
          <div v-if="isCommentExisting(bookingPositions, bookingDayPosition)" class="box projectBox commentBox">
            <div class="time-box">
              <Button
                v-if="
                  !isMobile() &&
                  !isDisabled &&
                  isBookingPositionChangeable(bookingPositions, bookingDayPosition, day) &&
                  isHoveringOverBookingDayPosition == bookingDayPosition.name
                "
                v-tooltip="'Buchungsposition tauschen'"
                class="time-button"
                icon="pi pi-sort-alt"
                @click="switchTimeForBookingPosition(bookingDayPosition)"
                @update:model-value="onChanged"
              ></Button>
              <DesktopTimePicker
                v-if="!isMobile()"
                v-model="bookingDayPosition.hours"
                :disabled="
                  isDisabled ||
                  isClosed(bookingPositions, bookingDayPosition) ||
                  disableTimeChangeInPast(bookingPositions, bookingDayPosition, day) ||
                  disableTimeChangeInFuture(bookingPositions, bookingDayPosition, day) ||
                  disableIfUserIsNotAllowedToBook(bookingPositions, bookingDayPosition)
                "
                @update:model-value="onChanged"
              />
              <TimePicker
                v-if="isMobile()"
                v-model="bookingDayPosition.hours"
                :disabled="
                  isDisabled ||
                  isClosed(bookingPositions, bookingDayPosition) ||
                  disableTimeChangeInPast(bookingPositions, bookingDayPosition, day) ||
                  disableTimeChangeInFuture(bookingPositions, bookingDayPosition, day) ||
                  disableIfUserIsNotAllowedToBook(bookingPositions, bookingDayPosition)
                "
                class="time-input-comment"
                @update:model-value="onChanged"
              />
              <IncreaseBookingPosition
                v-if="
                  isTimeToBookLeft &&
                  !disableTimeChangeInPast(bookingPositions, bookingDayPosition, day) &&
                  !disableTimeChangeInFuture(bookingPositions, bookingDayPosition, day)
                "
                @click="adjustBookingDayPosition(bookingDayPosition)"
              />
              <DecreaseBookingPosition
                v-if="
                  isDecreaseBookingPositionEnabledForBookingPosition(isOverbookedTime, bookingDayPosition.hours) &&
                  !disableTimeChangeInPast(bookingPositions, bookingDayPosition, day) &&
                  !disableTimeChangeInFuture(bookingPositions, bookingDayPosition, day)
                "
                @click="adjustBookingDayPosition(bookingDayPosition)"
              />
            </div>
            <AutoComplete
              v-model="bookingDayPosition.comment"
              :input-style="{ contentVisibility: bookingDayPosition.hours === '00:00' ? 'hidden' : 'visible' }"
              :class="{ 'p-invalid': v$.positionHours.$each.$response.$errors[index].comment.length }"
              :disabled="isDisabled || isClosed(bookingPositions, bookingDayPosition) || bookingDayPosition.hours === '00:00'"
              :suggestions="filteredComments"
              input-class="daycolumn-autocomplete-input"
              spellcheck="false"
              @complete="handleComplete($event, bookingDayPosition, allCommentsFromDay)"
              @update:model-value="onChanged"
            />
            <span v-if="v$.positionHours.$each.$response.$errors[index].comment.length" class="hint-msg p-error">
              {{ v$.positionHours.$each.$response.$errors[index].comment[0].$message }}
            </span>
          </div>
          <div v-else>
            <div class="box projectBox">
              <div class="time-box">
                <IncreaseBookingPosition
                  v-if="
                    isTimeToBookLeft &&
                    !disableTimeChangeInPast(bookingPositions, bookingDayPosition, day) &&
                    !disableTimeChangeInFuture(bookingPositions, bookingDayPosition, day)
                  "
                  @click="adjustBookingDayPosition(bookingDayPosition)"
                />
                <DecreaseBookingPosition
                  v-if="
                    isDecreaseBookingPositionEnabledForBookingPosition(isOverbookedTime, bookingDayPosition.hours) &&
                    !disableTimeChangeInPast(bookingPositions, bookingDayPosition, day) &&
                    !disableTimeChangeInFuture(bookingPositions, bookingDayPosition, day)
                  "
                  @click="adjustBookingDayPosition(bookingDayPosition)"
                />
                <DesktopTimePicker
                  v-if="!isMobile()"
                  v-model="bookingDayPosition.hours"
                  :disabled="
                    isDisabled ||
                    isClosed(bookingPositions, bookingDayPosition) ||
                    disableTimeChangeInPast(bookingPositions, bookingDayPosition, day) ||
                    disableTimeChangeInFuture(bookingPositions, bookingDayPosition, day) ||
                    disableIfUserIsNotAllowedToBook(bookingPositions, bookingDayPosition)
                  "
                  @update:model-value="onChanged"
                />
                <TimePicker
                  v-if="isMobile()"
                  v-model="bookingDayPosition.hours"
                  :disabled="
                    isDisabled ||
                    isClosed(bookingPositions, bookingDayPosition) ||
                    disableTimeChangeInPast(bookingPositions, bookingDayPosition, day) ||
                    disableTimeChangeInFuture(bookingPositions, bookingDayPosition, day) ||
                    disableIfUserIsNotAllowedToBook(bookingPositions, bookingDayPosition)
                  "
                  class="time-input"
                  @update:model-value="onChanged"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- no workday-->
      <div v-else class="p-d-flex p-flex-column">
        <div v-for="index in 3" :key="index" class="box noWorkTest"></div>
        <div v-for="(item, index) in day.positionHours" :key="index">
          <div v-if="isCommentExisting(bookingPositions, item)" class="box projectBox commentBox"></div>
          <div v-else>
            <div class="box projectBox"></div>
          </div>
        </div>
      </div>
      <div v-if="!isExternal">
        <SatisfactionMeter :day="day" @mood-changed="(mood: number) => setMood(mood)"></SatisfactionMeter>
      </div>
      <div class="box projectBox textareaBox">
        <Textarea v-model="day.notes" class="textarea" @change="notesChanged()"></Textarea>
      </div>
    </div>
    <ChangeBookingPositionPopUp
      v-if="isDialogOpen"
      :booking-position-description-by-name="updatedListForChangeBpPopUp"
      :booking-day-position="tempBookingDayPos"
      @change-hours-on-bp="changeHoursOnBP"
      @update:model-value="onChanged"
    ></ChangeBookingPositionPopUp>
  </div>
</template>

<script setup lang="ts">
import {
  BookingDay,
  BookingDayPosition,
  BookingDayPositionWithComment,
  BookingPositionDescription,
  erpNextTransportationProfile,
  erpNextUserProfile,
  WorkplaceBooking,
  workplaces,
} from "@/data-types";
import {
  addTimeStringsTogether,
  calculateEndTime,
  calculateNewTime,
  calcWorkTime,
  convertToErpDay,
  disableTimeChangeInFuture,
  disableTimeChangeInPast,
  filterComments,
  formatTimeToErp,
  getHoursFromTimeString,
  getWorkplaceDescription,
  isBookingDayInWeekend,
  isBookingPositionChangeable,
  isClosed,
  isCommentExisting,
  isDayResettable,
  isDecreaseBookingPositionEnabledForBookingPosition,
  isDecreaseBookingPositionShownForBreak,
  isMinimumBreakTimeTaken,
  resetBookingDayReactive,
} from "@/features/weekly/utils/DayColumn";
import { dialog, week } from "@/keys";
import ErpNext from "@/rest/ErpnextApi.ts";
import erpnextApi from "@/rest/ErpnextApi.ts";
import router from "@/router";
import BasePopUp from "@/ui/BasePopUp.vue";
import TimePicker from "@/ui/TimePicker.vue";
import TooltipWrapper from "@/ui/TooltipWrapper.vue";
import { buildTimeStringFromHours, formatDateWithoutYear, getWorkdayForDate, isMobile, roundTime } from "@/utils/Helper";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService.ts";
import { calculateWorkplaceRouterPath } from "@/utils/WorkplacePathCalculator";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength } from "@vuelidate/validators";
import debounce from "lodash.debounce";
import AutoComplete, { AutoCompleteCompleteEvent } from "primevue/autocomplete";
import Button, { ButtonPassThroughOptions } from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import ProgressSpinner from "primevue/progressspinner";
import SelectButton from "primevue/selectbutton";
import Textarea from "primevue/textarea";
import { ToastServiceMethods } from "primevue/toastservice";
import { useToast } from "primevue/usetoast";
import { computed, inject, onBeforeUnmount, onBeforeUpdate, onMounted, provide, reactive, Reactive, ref } from "vue";
import { onBeforeRouteLeave, RouterLink } from "vue-router";
import { useStore } from "vuex";
import ChangeBookingPositionPopUp from "./ChangeBookingPositionPopUp.vue";
import DesktopTimePicker from "./DesktopTimePicker.vue";
import SatisfactionMeter from "./SatisfactionMeter.vue";
import { PassThrough } from "primevue/ts-helpers";
import { useConfirm } from "primevue/useconfirm";
import { disableIfUserIsNotAllowedToBook } from "../utils/BookingRules";
import IncreaseBookingPosition from "@/features/weekly/components/components/IncreaseBookingPositionButton.vue";
import DecreaseBookingPosition from "@/features/weekly/components/components/DecreaseBookingPositionButton.vue";

interface Props {
  workDayInfo: BookingDay;
  bookingPositions: Map<string, BookingPositionDescription>;
  comments: BookingDayPositionWithComment[];
  userProfile: erpNextUserProfile;
  days: BookingDay[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  dayChanged: [void];
  workplaceChange: [workplace: string, transportationProfile: string];
  positionsFromDayColumn: [position: string];
}>();

const store = useStore();
const isExternal = computed(() => store.getters.isExternal);
const startTimeValue = store.getters.getStartTimeValue;
const endTimeValue = store.getters.getEndTimeValue;
const breakTime = store.getters.getBreakTime;
const day: Reactive<BookingDay> = reactive<BookingDay>(props.workDayInfo);
const date = new Date();
const allCommentsFromDay = ref<BookingDayPositionWithComment[]>(props.comments);
const filteredComments = ref<string[]>([]);
const debouncedWatch = ref();
const isEndTimeChanged = ref(false);
const isDisabled = ref(false);
const isCurrentDay = ref(false);
const workplaceEditorVisible = ref(false);
const currentWorkplace = ref<string>();
const currentTransportationProfile = ref<string>();
const isTimeToBookLeft = ref(false);
const isOverbookedTime = ref(false);
const waitForPush = ref(false);
const isUnsavedChanges = ref(false);
const showPopUp = ref(false);
const isDialogOpen = ref(false);
provide(dialog, isDialogOpen);
const BPTest = ref(props.bookingPositions as Map<string, BookingPositionDescription>);
const tempBookingDayPos = ref<BookingDayPosition>({ name: "", hours: "" });
const workplaceLoading = ref(true);
const popUpMessage = "Bitte warte einen Moment während die Daten gespeichert werden.";
// Somehow we could not fake/mock useToast from the test itself
const toast = import.meta.env.NODE_ENV !== "test" ? useToast() : ({} as ToastServiceMethods);
const confirm = useConfirm();
const isHoveringOverBookingDayPosition = ref<string>();

const desks = computed(() => store.getters.getDesks);
const workplaceBooking = ref<WorkplaceBooking>();
const workplaceDescription = computed(() => {
  const isExternal = store?.getters?.getEmployee?.is_external;
  const transportationIcon = store?.getters?.getTransportationProfiles?.find(
    (x: erpNextTransportationProfile) => x.name === workplaceBooking.value?.transportation_profile,
  )?.icon;
  return workplaceLoading.value
    ? ""
    : getWorkplaceDescription(workplaceBooking.value != null ? [workplaceBooking.value] : [], day, desks.value, isExternal, transportationIcon);
});

const transportations = computed(() => store.getters.getTransportationProfiles);

const size = 0.5;
const trashCanButtonProps: PassThrough<ButtonPassThroughOptions<any>> /*PassThrough<ButtonPassThroughOptions<any>>*/ = {
  icon: {
    style: { fontSize: size + "rem" },
  },
  root: {
    style: { width: size * 2.2 + "rem", height: size + "rem" },
  },
};

const currentWeek = inject(week, ref());
const workplaceRoute = computed(() => calculateWorkplaceRouterPath(currentWeek.value, getWorkdayForDate(new Date(day.currentDate))));

const endTimeDiff = ref<number>();

const rules = {
  end: {
    notFittingToBookings: helpers.withMessage(
      (x: any) => x.$response.$message,
      (endTime: string, day: BookingDay) => {
        const startTimeConv = getHoursFromTimeString(day.start);
        const breakTimeConv = getHoursFromTimeString(day.break);
        const workTimeConv = getHoursFromTimeString(calcWorkTime(day));
        const endTimeConv = getHoursFromTimeString(day.end);
        endTimeDiff.value = Math.round((endTimeConv - (breakTimeConv + workTimeConv + startTimeConv)) * 100) / 100;
        if (endTimeDiff.value < 0) {
          isTimeToBookLeft.value = false;
          isOverbookedTime.value = true;
          return {
            $valid: false,
            $message: `Überbucht:  ${buildTimeStringFromHours(-endTimeDiff.value)}`,
          };
        }
        if (endTimeDiff.value > 0) {
          isTimeToBookLeft.value = true;
          isOverbookedTime.value = false;
          return {
            $valid: false,
            $message: `Buchen: ${buildTimeStringFromHours(endTimeDiff.value)}`,
          };
        }
        isTimeToBookLeft.value = false;
        isOverbookedTime.value = false;
        return { $valid: true, $message: "" };
      },
    ),
    workTimeMustBeGreaterZero: helpers.withMessage("Keine Arbeitszeit", (endTime: string, day: BookingDay) => getHoursFromTimeString(calcWorkTime(day)) != 0),
    workMustEndSameDay: helpers.withMessage("Tag überschritten", (endTime: string) => getHoursFromTimeString(endTime) < 24),
  },
  break: {
    missingBreak: helpers.withMessage(
      (x: any) => x.$response.$message,
      (pause: string, day: BookingDay) => {
        const breakTimeConv = getHoursFromTimeString(pause);
        const workTimeConv = getHoursFromTimeString(calcWorkTime(day));
        if (workTimeConv > 6 && workTimeConv <= 9 && breakTimeConv < 0.5)
          return {
            $valid: false,
            $message: "Pause < 00:30",
          };
        if (workTimeConv > 9 && breakTimeConv < 0.75) return { $valid: false, $message: "Pause < 00:45" };
        return { $valid: true, $message: "" };
      },
    ),
  },
  positionHours: {
    $each: helpers.forEach({
      comment: {
        positionHasRequiredComment: helpers.withMessage("Kommentar nötig", (comment: string, position: BookingDayPosition) => {
          const bp = (props.bookingPositions as Map<string, BookingPositionDescription>).get(position.name);
          return !(bp?.requires_comment && position.hours !== "00:00" && position.comment?.length == 0);
        }),
        maxLength: helpers.withMessage(() => `Max 140 Zeichen`, maxLength(140)),
      },
    }),
  },
};
const v$ = useVuelidate(rules, day as any);
const workTime = computed(() => calcWorkTime(day));

if (day?.date?.getFullYear() === date.getFullYear() && day?.date?.getMonth() === date.getMonth() && day?.date?.getDate() === date.getDate() && !day?.end) {
  day.start = startTimeValue;
  day.break = breakTime;
  day.end = endTimeValue;
}

onMounted(async () => {
  debouncedWatch.value = debounce(async () => {
    beforeDebounced();
    await updateErpDayDebounced();
  });
});

const updateErpDayDebounced = debounce(async () => {
  await updateErpDay();
  isUnsavedChanges.value = false;
  showPopUp.value = false;
}, 1500);

const beforeDebounced = () => {
  isUnsavedChanges.value = true;
};

if (import.meta.env.NODE_ENV !== "test") {
  onBeforeRouteLeave((to, from, next) => {
    if (isUnsavedChanges.value === true || waitForPush.value === true) {
      showPopUp.value = true;
      next(false);
    } else next();
  });
}

onBeforeUnmount(() => {
  debouncedWatch.value.cancel();
});

onBeforeUpdate(() => {
  allCommentsFromDay.value = props.comments;
});

function hidePopUp() {
  showPopUp.value = false;
  isDialogOpen.value = false;
}

isDisabled.value = day.state === "Future" || day.booked;
if (!day.start) day.start = "";
if (!day.end) day.end = "00:00";
if (!day.break) day.break = "00:00";
//check for current day for mouse focus
const dayDate = new Date(day.currentDate);
const today = new Date();
isCurrentDay.value = dayDate.getDate() == today.getDate() && dayDate.getMonth() == today.getMonth() && dayDate.getFullYear() == today.getFullYear();

const isDayInWeekend = computed(() => isBookingDayInWeekend(day));

const canChangeWorkplace = computed(() => {
  const { booked: isBookingDayBooked, state } = day;
  if (isBookingDayBooked) return false;
  if (["Future", "On Leave", "Sick"].includes(state)) return false;
  if (isDayInWeekend.value) return true;

  return !isCurrentDay.value;
});

function handleComplete(
  { query: inputValue }: AutoCompleteCompleteEvent,
  bookingDayPosition: BookingDayPosition,
  bookingDayPositionWithComment: BookingDayPositionWithComment[],
): void {
  filteredComments.value = filterComments(inputValue, bookingDayPosition, bookingDayPositionWithComment);
}

function switchTimeForBookingPosition(bookingDayPosition: BookingDayPosition) {
  isDialogOpen.value = true;
  tempBookingDayPos.value = bookingDayPosition;
}

const updateEndTime = async () => {
  if (day.start === "") return;
  if (!isEndTimeChanged.value && props.userProfile.calculate_end_of_working_day) day.end = calculateEndTime(day);
  await v$.value.$validate();
  if (!v$.value.$invalid) isEndTimeChanged.value = false;
};

function roundTimes() {
  day.start = roundTime(day.start!);
  day.end = roundTime(day.end!);
  day.break = roundTime(day.break!);
  for (const position of day.positionHours) position.hours = roundTime(position.hours);
}

const updateErpDay = async () => {
  if (day.start !== "") {
    roundTimes();
  }
  await updateEndTime();
  const willSave = !v$.value.$invalid || day.start === "";
  if (willSave) {
    const dateFormat = formatDateWithoutYear(day.date);
    try {
      if (day.start !== "") {
        day.state = "Present";
      }
      emit("dayChanged");
      waitForPush.value = true;
      await ErpNext.updateDay(convertToErpDay(day));
      showSuccessToast(toast, `Tag aktualisiert (${dateFormat})`);
      waitForPush.value = false;
      localStorage.removeItem("startTimeValue");
      localStorage.removeItem("breakStartTimeValue");
      localStorage.removeItem("breakEndTimeValue");
      localStorage.removeItem("breakTime");
      localStorage.removeItem("endTimeValue");
    } catch (e) {
      showErrorToast(toast, `Aktualisierung fehlgeschlagen (${dateFormat}). ${e}`);
      waitForPush.value = false;
    }
  }
};

const checkForClear = async () => {
  if (day.start !== "") {
    day.start = "";
    day.state = "Absent";
    day.break = "00:00";
    day.positionHours.forEach((pH: BookingDayPosition) => (pH.hours = "00:00"));
    day.mood = 0;
    day.notes = "";
    debouncedWatch.value();
  }
};

const onChanged = () => updateEndTime().then(() => debouncedWatch.value());
const onEndTimeChanged = () => {
  isEndTimeChanged.value = true;
  debouncedWatch.value();
};

function setMood(mood: number) {
  day.mood = mood;
  debouncedWatch.value();
}

function wantToChangeWorkplace() {
  if (canChangeWorkplace.value) workplaceEditorVisible.value = true;
  else router.push(calculateWorkplaceRouterPath());
}

updateBookings();

async function updateBookings() {
  workplaceLoading.value = true;
  const currentBookings = await erpnextApi.getWorkplaceBookings(day.currentDate as any, 1, true);
  if (currentBookings?.length == 1) {
    currentWorkplace.value = currentBookings[0].workplace;
    workplaceBooking.value = currentBookings[0];
    currentTransportationProfile.value = currentBookings[0].transportation_profile;
  } else {
    currentWorkplace.value = undefined;
    workplaceBooking.value = undefined;
    currentTransportationProfile.value = store.getters?.getUserProfile?.default_transportation;
  }
  workplaceLoading.value = false;
}

function submitWorkplaceBooking() {
  if (currentWorkplace.value === "office" && currentTransportationProfile.value == null) {
    currentTransportationProfile.value = store.getters?.getUserProfile?.default_transportation;
  }
  erpnextApi
    .updateWorkplaceBooking(day.currentDate as any, currentWorkplace.value as string, undefined, undefined, currentTransportationProfile.value)
    .then(() => {
      updateBookings();
      showSuccessToast(toast, "Arbeitsort geändert");
      workplaceEditorVisible.value = false;
    })
    .catch(() => {
      showErrorToast(toast, "Arbeitsort konnte nicht geändert werden");
    });
}

function adjustBreakTime() {
  if (!endTimeDiff.value) return;
  if (!day.break) return;
  day.break = calculateNewTime(day.break, endTimeDiff.value);
  onChanged();
}

function adjustBookingDayPosition(bookingDayPosition: BookingDayPosition): void {
  if (!endTimeDiff.value) return;
  if (bookingDayPosition.hours) {
    bookingDayPosition.hours = calculateNewTime(bookingDayPosition.hours, endTimeDiff.value);
  } else {
    bookingDayPosition.hours = formatTimeToErp(buildTimeStringFromHours(endTimeDiff.value));
  }
  onChanged();
}

function notesChanged() {
  debouncedWatch.value();
}

const updatedListForChangeBpPopUp = computed(() => {
  const updatedMap = new Map();
  const currentDate = new Date(day.currentDate);

  for (const [key, bp] of BPTest.value.entries()) {
    const changeableFrom = bp.changeable_from ? new Date(bp.changeable_from) : null;
    const changeableTill = bp.changeable_till ? new Date(bp.changeable_till) : null;
    if (bp.closed) {
      continue;
    }
    if (changeableFrom && currentDate < changeableFrom) {
      continue;
    }
    if (changeableTill && currentDate > changeableTill) {
      continue;
    }

    updatedMap.set(key, bp);
  }
  return updatedMap;
});

const changeHoursOnBP = (data: any) => {
  const newBookingPosition = ref(data[0]);
  const oldBookingPosition = ref(data[1]);
  const keyBp = ref();
  for (const [key, entry] of BPTest.value) {
    if (entry.full_name == newBookingPosition.value.full_name) {
      keyBp.value = key;
    }
  }
  for (const presentDay of day.positionHours) {
    if (presentDay.name === oldBookingPosition.value) {
      const checkIfPositionExists = day.positionHours.findIndex((idx: any) => idx.name === keyBp.value);
      if (checkIfPositionExists === -1) {
        for (const days of props.days!) {
          if (day.currentDate === days.currentDate) {
            days.positionHours.push({ hours: presentDay.hours, name: keyBp.value, comment: presentDay.comment });
            presentDay.hours = "00:00";
            presentDay.comment = "";
            emit("positionsFromDayColumn", keyBp.value);
          }
          if (day.currentDate != days.currentDate) {
            days.positionHours.push({ hours: "00:00", name: keyBp.value, comment: "" });
            emit("positionsFromDayColumn", keyBp.value);
          }
        }
      } else {
        const objIdx = day.positionHours.findIndex((idx: any) => idx.name === keyBp.value);

        day.positionHours[objIdx].hours = addTimeStringsTogether(day.positionHours[objIdx].hours, presentDay.hours);
        day.positionHours[objIdx].comment = presentDay.comment;
      }
      presentDay.hours = "00:00";
      presentDay.comment = "";
    }
  }
  updateErpDay();
  isDialogOpen.value = false;
};

const showTrashcan = computed(() => isDayResettable(props.bookingPositions, day));

function resetDay() {
  confirm.require({
    message: `Bist Du sicher, dass Du den Tag vom ${new Date(day.currentDate).toLocaleDateString()} zurücksetzen möchtest?`,
    header: "Tag zurücksetzen",
    icon: "pi pi-info-circle",
    rejectClass: "p-button-primary p-button-outlined",
    acceptClass: "p-button-danger",
    rejectLabel: "Abbrechen",
    acceptLabel: "Zurücksetzen",
    accept: async () => {
      try {
        await erpnextApi.resetDay(new Date(day.currentDate));
        resetBookingDayReactive(day);
        await updateBookings();
        showSuccessToast(toast, "Tag zurückgesetzt");
      } catch (e: any) {
        showErrorToast(toast, `Fehler beim Zurücksetzen des Tages: ${e}`);
      }
    },
  });
}
</script>

<style scoped>
.time-box {
  display: flex;
  margin: 0.5rem 0;
  flex-flow: row wrap;
  align-items: center;
}

/*noinspection CssUnusedSymbol*/
.time-input-comment {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  min-width: 60%;
  width: calc(100% - 2px);
  display: flex;
}

.time-input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  min-width: 60%;
  height: calc(100% - 2px);
  display: flex;
}

.time-input:focus {
  outline: 2px solid var(--primary);
}

.time-button {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(1.1rem);
  max-width: calc(1.1rem);
  height: calc(1.1rem);
  margin: 0.1rem;
}

.breakButtonBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.time-button-project {
  position: absolute;
  top: 0.1rem;
  right: 0.2rem;
  width: calc(1.1rem);
  max-width: calc(1.1rem);
  height: calc(1.1rem);
}

/*noinspection CssUnusedSymbol*/
.p-inputtext {
  width: 2rem;
  min-width: 2rem;
  max-width: 2rem;
  text-align: center;
  justify-content: center;
  z-index: 0;
}

.isHoliday {
  background-color: var(--tableBackgroundHighlight);
}

.commentInputText:enabled:focus {
  z-index: 1;
  min-width: 200px;
  min-height: 5rem;
  position: absolute;
  overflow: auto;
  line-height: 0.86;
  transition: min-width 0.5s;
}

.commentInputText:disabled {
  text-decoration: line-through;
}

@media (max-width: 767px) {
  .commentInputText:enabled:focus {
    min-width: 89px;
  }

  .commentInputText:enabled:focus {
    min-width: 192px;
  }
}

.p-invalid {
  border-color: red;
}

@media (min-width: 2250px) {
  .commentInputText {
    min-width: 12.5rem;
  }

  .commentInputText:enabled:focus {
    min-width: 12.5rem;
    height: min-content;
  }
}

@media (min-width: 3200px) {
  .commentInputText {
    min-width: 19rem;
  }

  .commentInputText:enabled:focus {
    min-width: 19rem;
    height: min-content;
  }
}

.textarea {
  height: 100%;
  min-width: 100%;
  resize: none;
  text-align: left;
  border-radius: 0;
  border: 0;
}

.textareaBox {
  border-bottom: 0.1rem solid var(--borderColor);
  width: 100%;
}

.changeLocation {
  vertical-align: middle;
  padding: 0 !important;
  width: 8px !important;
  max-width: 8px !important;
  height: 8px !important;
  max-height: 8px !important;
}

.workplaceValid {
  color: var(--primary);
  cursor: pointer;
}

.workplaceInvalid {
  color: darkorange;
}

.weekend-holiday {
  text-decoration: none;
  color: var(--primary);
}

div:has(.hint-msg) {
  position: relative;
}

.hint-msg {
  font-size: 12px;
  position: absolute;
  bottom: -1.5px;
}
</style>
