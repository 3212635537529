<template>
  <div class="pop-up-card">
    <div class="pop-up">
      <div class="pop-up-text">
        {{ popUpMessage }}
      </div>
      <slot name="content"></slot>
      <div class="button-container">
        <div class="button-class">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  popUpMessage: string;
}

defineProps<Props>();
</script>

<style scoped>
.pop-up-card {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.pop-up {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--greystep);
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
}

.pop-up-text {
  font-size: large;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.button-class {
  flex: 0 0 50%;
  --button-class-gap: 12px;
  display: flex;
  gap: var(--button-class-gap);

  & > * {
    flex: 1 0 calc(100% / 2 - var(--button-class-gap));
    display: flex;
    justify-content: center;
  }
}
</style>
