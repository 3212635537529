import {
  AbsenceEvent,
  AnnualHours,
  AnnualStatement,
  BookingDay,
  BookingPosition,
  BookingPositionDescription,
  Desk,
  DeskBlocking,
  DesksBlockedByUser,
  DeskState,
  Employee,
  erpNextAvailableSkills,
  erpNextBankProfile,
  erpNextBaseProfile,
  erpNextBirthdays,
  erpNextCompanyMood,
  erpNextDaysInOfficeStats,
  erpNextHealthProfile,
  erpNextInventory,
  erpNextOrganisationChart,
  ErpNextPostResponse,
  erpNextProfileEducation,
  erpNextProfileExternalWorkHistory,
  erpNextProfileInternalWorkHistory,
  erpNextProfileSkill,
  erpNextProfileTraining,
  ErpNextTodayVisitorLog,
  erpNextTransportationProfile,
  erpNextUserProfile,
  ErpNextVisitorLog,
  ErpNextVisitorRegistration,
  erpNextWorkHistory,
  erpWorkHistoryProject,
  fileUploadResponse,
  HolidayRequest,
  LeaveType,
  PublicHoliday,
  UsedVoucher,
  WifiVoucherCreationDto,
  WorkplaceBooking,
  YearReport,
} from "@/data-types";
import httpApi from "@/rest/http-api";
import store from "@/store";
import moment from "moment";
import pLimit from "p-limit";

const limit = pLimit(1);
const limitWB = pLimit(5);

const login = async (email: string, password: string) => {
  const response = await limit(() =>
    httpApi.postRequest("login", {
      cmd: "login",
      usr: email,
      pwd: password,
    }),
  );

  if (response.ok) return;
  if (response.error?.response?.status === 401) throw new Error("Invalid email or password");
  throw new Error("Connection failed. Please retry later!");
};

const logout = async () => {
  const response = await limit(() => httpApi.getRequest("logout"));
  store.commit("setLoggedInUser", null);
  return !!response.ok;
};

const getAvailableBookingPositions = async (): Promise<BookingPosition[]> => {
  const response = await httpApi.postRequest("pep.mapo.query.get_available_booking_positions");
  return response.result?.data?.message ?? [];
};

const getBookingPositionDescriptionsByNames = async (names: string[]): Promise<BookingPositionDescription[]> => {
  const response = await httpApi.postRequest("pep.mapo.query.get_booking_position_descriptions", {
    namesJson: JSON.stringify(names),
  });
  return response.result?.data?.message ?? [];
};

const getTimesheetEntriesBetween = async (start_date: Date, days: number): Promise<BookingDay[]> => {
  const response = await httpApi.postRequest("pep.mapo.query.get_timesheet_entries_between", {
    start_date_str: moment(start_date).format("YYYY-MM-DD"),
    days: days,
  });
  if (!response.ok) throw new Error("Could not load timesheet entries for " + start_date + " Response: " + response.error?.response?.data?.message?.Message);
  else return response.result?.data?.message ?? [];
};

const updateDay = async (day: any) => {
  const result = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.update_day", {
      dataJson: JSON.stringify(day),
    }),
  );
  if (!result.ok) {
    const cleanupMessage = `Aktualisieren fehlgeschlagen. Grund: ${result.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

async function resetDay(date: Date) {
  const response = await httpApi.postRequest<ErpNextPostResponse>("pep.mapo.handlers.reset_day", {
    date: moment(date).format("YYYY-MM-DD"),
  });
  if (!response.ok) {
    throw new Error("Could not reset day. " + response.error);
  }
}

const getAnnualStatement = async (year: number): Promise<AnnualStatement> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_annual_statement", {
    year: year,
  });

  if (!response.ok) throw new Error("Could not load annual statement for year " + year + " Response: " + response.error);
  else return (response.result?.data?.message as AnnualStatement) ?? {};
};

const getAnnualHours = async (year: number): Promise<AnnualHours> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_annual_hours", {
    year: year,
  });
  return (response.result?.data?.message as AnnualHours) ?? {};
};

const getYearlyStatistics = async (year: number): Promise<YearReport> => {
  const response = await httpApi.postRequest("pep.mapo.query.get_yearly_statistics", {
    year: year,
  });
  return (response.result?.data?.message as YearReport) ?? {};
};

const getPublicHolidays = async (month: string, year: string): Promise<PublicHoliday[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_holidays", {
    month: month,
    year: year,
  });
  return (response.result?.data?.message as PublicHoliday[]) ?? [];
};

const getHolidayRequests = async (): Promise<HolidayRequest[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_leave_applications");
  return (response.result?.data?.message as HolidayRequest[]) ?? [];
};

const getIllnessRequests = async (): Promise<HolidayRequest[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_sick_applications");
  return (response.result?.data?.message as HolidayRequest[]) ?? [];
};

const getOffsites = async (month: string, year: string): Promise<AbsenceEvent[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_offsites", {
    month: month,
    year: year,
  });
  return (response.result?.data?.message as AbsenceEvent[]) ?? [];
};

const getHolidayOptions = async (): Promise<LeaveType[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_leave_types");
  return response.result?.data?.message ?? [];
};

const postHolidayRequest = async (start: string, days: number, description: string): Promise<string> => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.create_vacation_note", {
      start: start,
      days: days,
      description: description,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Anlegen fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
  return response.result?.data.message.Name;
};

const postSickRequest = async (start: string, days: number, description: string): Promise<string> => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.create_sick_note", {
      start: start,
      days: days,
      description: description,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Anlegen fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
  return response.result?.data.message.Name;
};

const deleteLeaveApplication = async (name: string): Promise<any> => {
  const response = await limit(() =>
    httpApi.postRequest(`pep.mapo.handlers.delete_leave_application`, {
      name: name,
    }),
  );
  if (!response.ok) {
    throw new Error("Antrag kann nicht mehr gelöscht werden.");
  } else {
    return response.result?.data?.message ?? [];
  }
};

const getAbsences = async (month: string, year: string): Promise<AbsenceEvent[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_absences", {
    month: month,
    year: year,
  });
  return response.result?.data?.message ?? [];
};

const postIllnessFile = async (formData: FormData): Promise<fileUploadResponse> => {
  const response = await limit(() => httpApi.postRequest("upload_file", formData));
  if (!response.ok) {
    throw new Error(`Could not update file. ${response.error?.response?.data?.message?.Message}`);
  }
  return response.result?.data?.message;
};

const getMicrosoft365Login = async () => {
  const response = await httpApi.getRequest("pep.mapo.query.get_microsoft_365_login");
  return response.result?.data?.message ?? "";
};

const getWorkplaceBookings = async (startDate: Date, days: number, quick = false): Promise<WorkplaceBooking[]> => {
  const response = await limitWB(() =>
    httpApi.postRequest("pep.mapo.query.get_workplace_bookings", {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      days,
      quick,
    }),
  );
  const result = response.result?.data?.message ?? [];
  for (const booking of result) {
    booking.date = new Date(booking.date);
  }
  return result;
};
const getFavoriteEmployeeGroups = async (): Promise<Map<string, string[]>> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_favorite_employee_groups");
  const result = new Map<string, string[]>();
  for (const group of response.result?.data?.message ?? []) {
    result.set(group.name, group.favorites);
  }
  return result;
};

const getEmployee = async (): Promise<Employee | null> => {
  const response = await httpApi.postRequest("pep.mapo.query.get_employee");
  return response.result?.data?.message ?? null;
};

const getEmployees = async (): Promise<Employee[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_employees");
  const result = response.result?.data?.message ?? [];
  for (const employee of result) {
    employee.full_name = employee.first_name + " " + employee.last_name;
    employee.abbreviation = employee.abbr;
  }
  return result;
};

const updateWorkplaceBooking = async (
  date: Date,
  workplace: string,
  desk?: string,
  note?: string,
  transportation_profile?: string,
  parking_lot_desired = false,
) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.update_workplace_booking", {
      date: moment(date).format("YYYY-MM-DD"),
      workplace,
      desk,
      note,
      transportation_profile,
      parking_lot_desired,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Aktualisieren fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const getDesks = async (): Promise<Desk[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_desks");
  return response.result?.data?.message ?? [];
};

const getDeskStatus = async (date: Date): Promise<DeskState[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_desk_status", {
    date: moment(date).format("YYYY-MM-DD"),
  });
  return response.result?.data?.message ?? [];
};

const updateFavoriteEmployeeGroup = async (group: string, names: string[]) => {
  const conditionalString = "pep.mapo.handlers.update_favorite_employee_group";
  const response = await limit(() =>
    httpApi.postRequest(conditionalString, {
      group: group,
      names: JSON.stringify(names),
    }),
  );
  if (!response.ok) {
    throw new Error("Favoriten konnten nicht geändert werden.");
  }
};

const CannotUpdateProfileErrorMessage = "Benutzerprofil kann nicht aktualisiert werden";
const CannotGetProfileErrorMessage = "Benutzerprofil kann nicht geladen werde";
const ProfileUpdatedMessage = "Benutzerprofil aktualisiert";

const getProfileSettings = async (): Promise<erpNextUserProfile> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_profile");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextUserProfile;
  }
};

const updateProfileSettings = async (userProfile: erpNextUserProfile) => {
  const response = await limit(() => httpApi.postRequest("pep.mapo.handlers.update_profile_settings", userProfile));
  if (!response.ok) {
    throw new Error(CannotUpdateProfileErrorMessage);
  }
};

const getProfileBaseData = async (): Promise<erpNextBaseProfile> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_profile_basedata");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextBaseProfile;
  }
};

const updateProfileBaseData = async (baseProfile: erpNextBaseProfile) => {
  const response = await limit(() => httpApi.postRequest("pep.mapo.handlers.update_profile_basedata", baseProfile));
  if (!response.ok) {
    throw new Error(CannotUpdateProfileErrorMessage);
  }
};

const getProfileBankAccount = async (): Promise<erpNextBankProfile> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_profile_bankaccount");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextBankProfile;
  }
};

const updateProfileBankAccount = async (bankProfile: erpNextBankProfile) => {
  const response = await limit(() => httpApi.postRequest("pep.mapo.handlers.update_profile_bankaccount", bankProfile));
  if (!response.ok) {
    throw new Error(CannotUpdateProfileErrorMessage);
  }
};

const getProfileHealthInsurance = async (): Promise<erpNextHealthProfile> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_profile_health_insurance");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextHealthProfile;
  }
};

const updateProfileHealthInsurance = async (healthProfile: erpNextHealthProfile) => {
  const response = await limit(() => httpApi.postRequest("pep.mapo.handlers.update_profile_health_insurance", healthProfile));
  if (!response.ok) {
    throw new Error(CannotUpdateProfileErrorMessage);
  }
};

const getProfileWorkHistory = async (): Promise<erpNextWorkHistory> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_profile_work_history");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextWorkHistory;
  }
};

const getAvailableWorkHistoryProjects = async (): Promise<erpWorkHistoryProject[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_available_work_history_projects");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpWorkHistoryProject[];
  }
};

const updateProfileWorkHistory = async (workHistoryProfile: erpNextWorkHistory) => {
  const response = await limit(() => httpApi.postRequest("pep.mapo.handlers.update_profile_work_history", workHistoryProfile));
  if (!response.ok) {
    throw new Error(CannotUpdateProfileErrorMessage);
  }
};

const getCompanyMood = async (): Promise<erpNextCompanyMood> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_company_mood");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextCompanyMood;
  }
};

const getBirthdays = async (): Promise<erpNextBirthdays> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_birthdays");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextBirthdays;
  }
};

const getProfileEducation = async (): Promise<erpNextProfileEducation[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_profile_education");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextProfileEducation[];
  }
};

const deleteProfileEducation = async (name: string): Promise<any> => {
  const response = await limit(() =>
    httpApi.postRequest(`pep.mapo.handlers.delete_profile_education`, {
      name: name,
    }),
  );
  if (!response.ok) {
    throw new Error("Ausbildung konnte nicht gelöscht werden.");
  } else {
    return response.result?.data?.message ?? [];
  }
};
const updateProfileEducation = async (
  name: string,
  school_univ: string,
  qualification: string,
  level: string,
  year_of_passing: string,
  class_per?: string,
  maj_opt_subj?: string,
) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.update_profile_education", {
      name,
      school_univ,
      qualification,
      level,
      year_of_passing,
      class_per,
      maj_opt_subj,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Aktualisieren fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};
const addProfileEducation = async (
  school_univ: string,
  qualification: string,
  level: string,
  year_of_passing: number,
  class_per?: string,
  maj_opt_subj?: string,
) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.add_profile_education", {
      school_univ,
      qualification,
      level,
      year_of_passing,
      class_per,
      maj_opt_subj,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Anlegen fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const getProfileExternalWorkHistory = async (): Promise<erpNextProfileExternalWorkHistory[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_profile_external_work_history");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextProfileExternalWorkHistory[];
  }
};

const updateExternalWorkHistory = async (
  name: string,
  designation: string,
  project_content: string,
  tasks: string,
  keywords: string,
  total_experience: string,
  end_date: string,
  team_size: string,
  customer_category: string,
  company_name?: string,
) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.update_profile_external_work_history", {
      name,
      designation,
      project_content,
      tasks,
      keywords,
      total_experience,
      end_date,
      team_size,
      customer_category,
      company_name,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Aktualisieren fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const addExternalWorkHistory = async (
  designation: string,
  project_content: string,
  tasks: string,
  keywords: string,
  total_experience: string,
  end_date: string,
  team_size: string,
  customer_category: string,
  company_name?: string,
) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.add_profile_external_work_history", {
      designation,
      project_content,
      tasks,
      keywords,
      total_experience,
      end_date,
      team_size,
      customer_category,
      company_name,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Anlegen fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const deleteExternalWorkHistory = async (name: string): Promise<any> => {
  const response = await limit(() =>
    httpApi.postRequest(`pep.mapo.handlers.delete_profile_external_work_history`, {
      name: name,
    }),
  );
  if (!response.ok) {
    throw new Error("Vorherige Berufserfahrung konnte nicht gelöscht werden.");
  } else {
    return response.result?.data?.message ?? [];
  }
};

const getDaysInOfficeStats = async (): Promise<erpNextDaysInOfficeStats> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_days_in_office_statistics");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextDaysInOfficeStats;
  }
};

const getInternalWorkHistory = async (): Promise<erpNextProfileInternalWorkHistory[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_profile_internal_work_history");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    const result = response.result.data.message as erpNextProfileInternalWorkHistory[];
    for (const entry of result) {
      entry.from_date = new Date(entry.from_date);
      entry.to_date = new Date(entry.to_date);
    }
    return result;
  }
};

const updateInternalWorkHistory = async (
  name: string,
  designation_desc: string,
  project: string,
  project_content: string,
  tasks: string,
  keywords: string,
  from_date: string,
  to_date: string,
  team_size: string,
  customer_category: string,
) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.update_profile_internal_work_history", {
      name,
      designation_desc,
      project,
      project_content,
      tasks,
      keywords,
      from_date,
      to_date,
      team_size,
      customer_category,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Aktualisieren fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const addInternalWorkHistory = async (
  name: string,
  designation_desc: string,
  project: string,
  project_content: string,
  tasks: string,
  keywords: string,
  from_date: string,
  to_date: string,
  team_size: string,
  customer_category: string,
) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.add_profile_internal_work_history", {
      name,
      designation_desc,
      project,
      project_content,
      tasks,
      keywords,
      from_date,
      to_date,
      team_size,
      customer_category,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Anlegen fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const deleteInternalWorkHistory = async (name: string): Promise<any> => {
  const response = await limit(() =>
    httpApi.postRequest(`pep.mapo.handlers.delete_profile_internal_work_history`, {
      name: name,
    }),
  );
  if (!response.ok) {
    throw new Error("Projekt konnte nicht gelöscht werden.");
  } else {
    return response.result?.data?.message ?? [];
  }
};

const getAvailableSkills = async (): Promise<erpNextAvailableSkills[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_skills");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextAvailableSkills[];
  }
};

const getProfileSkill = async (): Promise<erpNextProfileSkill[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_profile_skills");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextProfileSkill[];
  }
};

const updateProfileSkill = async (name: string, skill: string, proficiency: string, evaluation_date: string) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.update_profile_skill", {
      name,
      skill,
      proficiency,
      evaluation_date,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Aktualisieren fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const addProfileSkill = async (skill: string, proficiency: string, evaluation_date: string) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.add_profile_skill", {
      skill,
      proficiency,
      evaluation_date,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Anlegen fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const deleteProfileSkill = async (name: string): Promise<any> => {
  const response = await limit(() =>
    httpApi.postRequest(`pep.mapo.handlers.delete_profile_skill`, {
      name: name,
    }),
  );
  if (!response.ok) {
    throw new Error("Projekt konnte nicht gelöscht werden.");
  }
};

const getProfileTraining = async (): Promise<erpNextProfileTraining[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_profile_trainings");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextProfileTraining[];
  }
};

const updateProfileTraining = async (name: string, training_date: string, training_type: string, designation: string, duration: string) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.update_profile_training", {
      name,
      designation,
      training_date,
      training_type,
      duration,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Aktualisieren fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const addProfileTraining = async (training_date: string, training_type: string, designation: string, duration: string) => {
  const response = await limit(() =>
    httpApi.postRequest("pep.mapo.handlers.add_profile_training", {
      training_date,
      training_type,
      designation,
      duration,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Anlegen fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const deleteProfileTraining = async (name: string): Promise<any> => {
  const response = await limit(() =>
    httpApi.postRequest(`pep.mapo.handlers.delete_profile_training`, {
      name: name,
    }),
  );
  if (!response.ok) {
    throw new Error("Kurse und Zertifizierungen konnten nicht gelöscht werden.");
  }
};

const getInventory = async (): Promise<erpNextInventory[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_assets");
  if (!response.ok) {
    throw new Error(CannotGetProfileErrorMessage);
  } else {
    return response.result.data.message as erpNextInventory[];
  }
};

const confirmInventoryItem = async (issue_name: string): Promise<any> => {
  const response = await limit(() =>
    httpApi.postRequest(`pep.mapo.handlers.confirm_asset_issue`, {
      issue_name: issue_name,
    }),
  );
  if (!response.ok) {
    throw new Error("Gegenstand konnte nicht Gegengezeichnet werden.");
  }
};

const getTransportationProfiles = async (): Promise<erpNextTransportationProfile[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_transportation_profiles");
  if (!response.ok) {
    throw new Error("Fehler beim Abrufen der Profile");
  }
  return response.result.data.message as erpNextTransportationProfile[];
};

const deleteTransportationProfile = async (name: string): Promise<any> => {
  const response = await limit(() =>
    httpApi.postRequest(`pep.mapo.handlers.delete_transportation_profile`, {
      name: name,
    }),
  );
  if (!response.ok) {
    throw new Error("Profil konnte nicht gelöscht werden");
  }
};

const addTransportationProfile = async (profile: erpNextTransportationProfile): Promise<any> => {
  const response = await limit(() => httpApi.postRequest(`pep.mapo.handlers.add_transportation_profile`, profile));
  if (!response.ok) {
    throw new Error("Profil konnte nicht erstellt werden");
  }
};

const updateTransportationProfile = async (profile: erpNextTransportationProfile): Promise<any> => {
  const response = await limit(() => httpApi.postRequest(`pep.mapo.handlers.update_transportation_profile`, profile));
  if (!response.ok) {
    throw new Error("Profil konnte nicht erstellt werden");
  }
};

const getVisitorLogs = async (): Promise<ErpNextVisitorLog[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_visitor_logs");
  if (!response.ok) {
    throw new Error("Fehler beim Abrufen der Besucherliste");
  } else {
    const res = response.result.data.message as ErpNextVisitorLog[];
    for (const entry of res) {
      entry.date = new Date(entry.date);
      if (entry.left) entry.left = new Date(entry.left);
    }
    return res;
  }
};

const logoutVisitor = async (name: string, left_at?: string): Promise<any> => {
  const response = await limit(() =>
    httpApi.postRequest(`pep.mapo.handlers.logout_visitor`, {
      name: name,
      left_at,
    }),
  );
  if (!response.ok) {
    const cleanupMessage = `Logout fehlgeschlagen. Grund: ${response.error?.response?.data?.message?.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
};

const preregisterVisitor = async (erpNextVisitorRegistration: ErpNextVisitorRegistration) => {
  const response = await httpApi.postRequest<ErpNextPostResponse>(`pep.mapo.handlers.preregister_visitor`, { ...erpNextVisitorRegistration });
  if (!response.ok) {
    const cleanupMessage = `Besucher konnte nicht vorgemerkt werden.\nGrund: ${response.error?.response?.data?.message.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
  return response;
};

const getTodayVisitors = async (): Promise<ErpNextTodayVisitorLog[]> => {
  const response = await httpApi.getRequest(`pep.mapo.query.get_today_visitors`);
  if (!response.ok) {
    const cleanupMessage = `Heutige Besucher konnten nicht geladen werden.\nGrund: ${response.error?.response?.data?.message.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
  const res = response.result.data.message as ErpNextVisitorLog[];
  res.forEach((entry) => {
    entry.date = new Date(entry.date);
    if (entry.left) entry.left = new Date(entry.left);
  });
  return res;
};

const getOrganisationChart = async () => {
  const response = await httpApi.getRequest("pep.mapo.query.get_organisation_tree");
  if (!response.ok) {
    throw new Error("Fehler beim Abrufen der Organisations-Daten");
  }
  return response.result.data.message as erpNextOrganisationChart;
};

const getDeskBlockingsCreatedByUser = async () => {
  const response = await httpApi.getRequest("pep.mapo.query.get_desk_blockings");
  if (!response.ok) {
    throw new Error("Fehler beim Abrufen der vom User blockierten Tische");
  }
  return response.result.data.message as DesksBlockedByUser[];
};

const addDeskBlocking = async (deskBlocking: DeskBlocking) => {
  const response = await httpApi.postRequest("pep.mapo.handlers.add_desk_blocking", { ...deskBlocking });
  if (!response.ok) {
    const cleanupMessage = `Fremdreservierung fehlgeschlagen.\nGrund: ${response.error?.response?.data?.message.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
  return response;
};

const deleteDeskBlocking = async (deskName: string, date: string) => {
  const response = await httpApi.postRequest("pep.mapo.handlers.delete_desk_blocking", {
    desk: deskName,
    date: date,
  });
  if (!response.ok) {
    const cleanupMessage = `Stornierung der Fremdreservierung ist fehlgeschlagen.\nGrund: ${response.error?.response?.data?.message.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
  return response;
};

const createVoucherCode = async (wifiVoucherCreationDto: WifiVoucherCreationDto) => {
  const response = await httpApi.postRequest("pep.mapo.handlers.create_voucher_code", wifiVoucherCreationDto);
  if (!response.ok) {
    const cleanupMessage = `Generierung eine Wlan Vouchers Fehlgeschlagen.\nGrund: ${response.error?.response?.data?.message.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
  return response?.result?.data.message.Voucher as string;
};

const getUsedVoucherCodes = async (): Promise<UsedVoucher[]> => {
  const response = await httpApi.getRequest("pep.mapo.query.get_used_vouchers");
  if (!response.ok) {
    const errorMessage = `Fehler beim Abrufen der Voucher-Codes.\nGrund: ${response.error?.response?.data?.message.Message ?? "Unbekannter Fehler"}`;
    throw new Error(errorMessage);
  }
  return response.result.data.message;
};

async function sendVisitorCodeAsMail(visitorName: string): Promise<void> {
  const response = await httpApi.postRequest("pep.mapo.handlers.send_mail_to_preregistered_visitor", {
    name: visitorName,
  });
  if (!response.ok) {
    const cleanupMessage = `Fehler beim Versand des Besuchercodes per E-Mail.\nGrund: ${response.error?.response?.data?.message.Message ?? "Unbekannter Fehler"}`;
    throw new Error(cleanupMessage);
  }
}

export default {
  ProfileUpdatedMessage,
  addDeskBlocking,
  addExternalWorkHistory,
  addInternalWorkHistory,
  addProfileEducation,
  addProfileSkill,
  addProfileTraining,
  addTransportationProfile,
  confirmInventoryItem,
  createVoucherCode,
  deleteDeskBlocking,
  deleteExternalWorkHistory,
  deleteInternalWorkHistory,
  deleteLeaveApplication,
  deleteProfileEducation,
  deleteProfileSkill,
  deleteProfileTraining,
  deleteTransportationProfile,
  getAbsences,
  getAnnualHours,
  getAnnualStatement,
  getAvailableBookingPositions,
  getAvailableSkills,
  getAvailableWorkHistoryProjects,
  getBirthdays,
  getBookingPositionDescriptionsByNames,
  getCompanyMood,
  getDaysInOfficeStats,
  getDeskBlockingsCreatedByUser,
  getDeskStatus,
  getDesks,
  getEmployee,
  getEmployees,
  getFavoriteEmployeeGroups,
  getHolidayOptions,
  getHolidayRequests,
  getIllnessRequests,
  getInternalWorkHistory,
  getInventory,
  getMicrosoft365Login,
  getOffsites,
  getOrganisationChart,
  getProfileBankAccount,
  getProfileBaseData,
  getProfileEducation,
  getProfileExternalWorkHistory,
  getProfileHealthInsurance,
  getProfileSettings,
  getProfileSkill,
  getProfileTraining,
  getProfileWorkHistory,
  getPublicHolidays,
  getTimesheetEntriesBetween,
  getTodayVisitors,
  getTransportationProfiles,
  getUsedVoucherCodes,
  getVisitorLogs,
  getWorkplaceBookings,
  getYearlyStatistics,
  limitWB,
  login,
  logout,
  logoutVisitor,
  postHolidayRequest,
  postIllnessFile,
  preregisterVisitor,
  resetDay,
  sendVisitorCodeAsMail,
  updateDay,
  updateExternalWorkHistory,
  updateFavoriteEmployeeGroup,
  updateInternalWorkHistory,
  updateProfileBankAccount,
  updateProfileBaseData,
  updateProfileEducation,
  updateProfileHealthInsurance,
  updateProfileSettings,
  updateProfileSkill,
  updateProfileTraining,
  updateProfileWorkHistory,
  updateTransportationProfile,
  updateWorkplaceBooking,
  postSickRequest,
};
