<template>
  <div :id="getWorkdayForDate(booking.date)" style="font-size: 1.5rem; font-weight: bold" class="p-my-2 p-mr-3">
    {{ getWorkdayForDate(booking.date) }} {{ formatDateWithoutYear(booking.date) }}
  </div>
</template>

<script setup lang="ts">
import { formatDateWithoutYear, getWorkdayForDate } from "@/utils/Helper";
import { WorkplaceBooking } from "@/data-types";

interface Props {
  booking: WorkplaceBooking;
}
defineProps<Props>();
</script>

<style scoped></style>
