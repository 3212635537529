import { Module } from "vuex";
import { RootState } from "@/store";

export interface StoreLeaveType {
  name: string;
  title: string;
  code: number;
}

export interface HolidayStoreState {
  holidayOptions: StoreLeaveType[];
}

const holidayStore: Module<HolidayStoreState, RootState> = {
  state: () => ({
    holidayOptions: <StoreLeaveType[]>[],
  }),
  getters: {
    getHolidayOptions(state: HolidayStoreState): StoreLeaveType[] {
      return state.holidayOptions;
    },
  },
  mutations: {
    setHolidayOptions(state: HolidayStoreState, holidayOptions: StoreLeaveType[]) {
      state.holidayOptions = holidayOptions;
    },
  },
  actions: {},
};

export default holidayStore;
