<template>
  <Dialog
    v-model:visible="displayDialog"
    header="Projekte bei pep.digital"
    :dismissable-mask="true"
    :modal="true"
    :style="{ width: isMobile ? '100vw' : '55vw' }"
  >
    <div>
      <div class="dialogContainer">
        <div class="card flex justify-content-center educationInput">
          <span>Projekt</span>
          <Dropdown
            v-model="v$.project.$model"
            :style="{ width: isMobile ? '80vw' : '42vw' }"
            :options="uniqueProjectNamesArray"
            option-label="description"
            placeholder="Wähle ein Projekt aus"
            class="w-full md:w-14rem"
            :class="{ 'p-invalid': v$.project.$invalid && submitted }"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ knownProjects[slotProps.value]?.project_name }}</div>
              </div>
              <span v-else>
                {{ knownProjects[intWorkHistory.project]?.project_name || "Wähle ein Projekt aus" }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div>{{ knownProjects[slotProps.option]?.project_name }}</div>
              </div>
            </template>
          </Dropdown>
          <small v-if="v$.project.$invalid && submitted" class="p-error">
            {{ v$.project.required.$message }}
          </small>
        </div>
        <div v-if="isProjectEmpty" class="card flex justify-content-center educationInput">
          <span>Projektbeschreibung *</span>
          <Textarea
            v-model="v$.designation_desc.$model"
            :style="{ width: isMobile ? '80vw' : '42vw' }"
            auto-resize
            rows="5"
            cols="30"
            :class="{ 'p-invalid': v$.designation_desc.$invalid && submitted }"
          />
          <small v-if="v$.designation_desc.$invalid && submitted" class="p-error">
            {{ v$.designation_desc.required.$message }}
          </small>
        </div>
        <div v-if="isProjectEmpty" class="card flex justify-content-center educationInput">
          <span>Projektinhalt *</span>
          <Editor
            v-model="v$.project_content.$model"
            :style="{ width: isMobile ? '80vw' : '42vw' }"
            auto-resize
            rows="5"
            cols="30"
            :class="{ 'p-invalid': v$.project_content.$invalid && submitted }"
          />
          <small v-if="v$.project_content.$invalid && submitted" class="p-error">
            {{ v$.project_content.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Aufgaben *</span>
          <Textarea
            v-model="v$.tasks.$model"
            :style="{ width: isMobile ? '80vw' : '42vw' }"
            auto-resize
            rows="5"
            cols="30"
            :class="{ 'p-invalid': v$.tasks.$invalid && submitted }"
          />
          <small v-if="v$.tasks.$invalid && submitted" class="p-error">
            {{ v$.tasks.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Schlüsselwörter *</span>
          <InputText
            v-model="v$.keywords.$model"
            :style="{ width: isMobile ? '80vw' : '42vw' }"
            type="text"
            :class="{ 'p-invalid': v$.keywords.$invalid && submitted }"
          />
          <small v-if="v$.keywords.$invalid && submitted" class="p-error">
            {{ v$.keywords.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Zeitpunkt Projektstart *</span>
          <Calendar v-model="v$.from_date.$model" type="text" date-format="dd.mm.yy" :class="{ 'p-invalid': v$.from_date.$invalid && submitted }" />
          <small v-if="v$.from_date.$invalid && submitted" class="p-error">
            {{ v$.from_date.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Zeitpunkt Projektende *</span>
          <Calendar v-model="v$.to_date.$model" type="text" date-format="dd.mm.yy" :class="{ 'p-invalid': v$.to_date.$invalid && submitted }" />
          <small v-if="v$.to_date.$invalid && submitted" class="p-error">
            {{ v$.to_date.required.$message }}
          </small>
        </div>
        <div v-if="isProjectEmpty" class="card flex justify-content-center educationInput">
          <span>Firmenkategorie *</span>
          <InputText
            v-model="v$.customer_category.$model"
            :style="{ width: isMobile ? '80vw' : '42vw' }"
            type="text"
            :class="{ 'p-invalid': v$.customer_category.$invalid && submitted }"
          />
          <small v-if="v$.customer_category.$invalid && submitted" class="p-error">
            {{ v$.customer_category.required.$message }}
          </small>
        </div>
        <div v-if="isProjectEmpty" class="card flex justify-content-center educationInput">
          <span>Teamgröße *</span>
          <InputText v-model="v$.team_size.$model" type="text" :class="{ 'p-invalid': v$.team_size.$invalid && submitted }" />
          <small v-if="v$.team_size.$invalid && submitted" class="p-error">
            {{ v$.team_size.required.$message }}
          </small>
        </div>
      </div>
      <div class="editButton">
        <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
        <Button v-if="intWorkHistory.name && !isLoading" @click="updateProfileInternalWorkHistory(!v$.$invalid)"> Aktualisieren </Button>
        <Button v-if="!intWorkHistory.name && !isLoading" @click="addProfileInternalWorkHistory(!v$.$invalid)"> Hinzufügen </Button>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { computed, inject, onMounted, ref, watch } from "vue";
import erpnextApi from "@/rest/ErpnextApi.ts";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { dialog } from "@/keys";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import Editor from "primevue/editor";
import { erpNextProfileInternalWorkHistory, erpWorkHistoryProject } from "@/data-types";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService.ts";
import { formatDateStringToERP } from "@/features/profile/utils/Profile";

interface Props {
  internalWorkHistory: erpNextProfileInternalWorkHistory;
  isNewEntry: boolean;
  knownProjects: { [key: string]: erpWorkHistoryProject };
}

const props = defineProps<Props>();
const emit = defineEmits<{ DataChanged: [] }>();

const intWorkHistory = ref<erpNextProfileInternalWorkHistory>(props.internalWorkHistory);
const toast = useToast();
const isLoading = ref(false);
const displayDialog = inject(dialog, ref(false));
const width = window.innerWidth;
const isMobile = ref(false);
const isProjectEmpty = computed(() => props.knownProjects[intWorkHistory.value.project]?.editable);
const uniqueProjectNamesArray = ref();

const rules = {
  name: {},
  designation_desc: { required: helpers.withMessage("Bitte Projektbeschreibung angeben", requiredIf(isProjectEmpty)) },
  project: {},
  project_content: { required: helpers.withMessage("Bitte Projektinhalt angeben", requiredIf(isProjectEmpty)) },
  tasks: { required: helpers.withMessage("Bitte Aufgaben angeben", required) },
  keywords: { required: helpers.withMessage("Bitte Schlüsselwörter angeben", required) },
  from_date: { required: helpers.withMessage("Bitte Projektstart angeben", required) },
  to_date: { required: helpers.withMessage("Bitte Projektende angeben", required) },
  team_size: { required: helpers.withMessage("Bitte Teamgröße angeben", requiredIf(isProjectEmpty)) },
  customer_category: { required: helpers.withMessage("Bitte Firmenkategorie angeben", requiredIf(isProjectEmpty)) },
};

const v$ = useVuelidate(rules, intWorkHistory);
const submitted = ref(false);

watch(
  () => props.internalWorkHistory,
  (newVal) => {
    intWorkHistory.value = newVal;
  },
);

onMounted(() => {
  if (width < 1400) {
    isMobile.value = true;
  }
});

uniqueProjectNamesArray.value = Object.keys(props.knownProjects);

async function updateProfileInternalWorkHistory(isFormValid: boolean) {
  submitted.value = true;
  if (!isFormValid) {
    showErrorToast(toast, "Bitte die Fehler in den Eingabefeldern beheben");
    return;
  }
  isLoading.value = true;
  await erpnextApi
    .updateInternalWorkHistory(
      intWorkHistory.value.name,
      intWorkHistory.value.designation_desc,
      intWorkHistory.value.project,
      intWorkHistory.value.project_content,
      intWorkHistory.value.tasks,
      intWorkHistory.value.keywords,
      formatDateStringToERP(intWorkHistory.value.from_date.toString()),
      formatDateStringToERP(intWorkHistory.value.to_date.toString()),
      intWorkHistory.value.team_size,
      intWorkHistory.value.customer_category,
    )
    .then(() => {
      showSuccessToast(toast, "Projekt erfolgreich aktualisiert");
      emit("DataChanged");
      displayDialog.value = false;
    })
    .catch((err: Error) => showErrorToast(toast, err.message))
    .finally(() => (isLoading.value = false));
}

async function addProfileInternalWorkHistory(isFormValid: boolean) {
  submitted.value = true;
  if (!isFormValid) {
    showErrorToast(toast, "Bitte die Fehler in den Eingabefeldern beheben");
    return;
  }
  isLoading.value = true;
  await erpnextApi
    .addInternalWorkHistory(
      intWorkHistory.value.name,
      intWorkHistory.value.designation_desc,
      intWorkHistory.value.project,
      intWorkHistory.value.project_content,
      intWorkHistory.value.tasks,
      intWorkHistory.value.keywords,
      formatDateStringToERP(intWorkHistory.value.from_date.toString()),
      formatDateStringToERP(intWorkHistory.value.to_date.toString()),
      intWorkHistory.value.team_size,
      intWorkHistory.value.customer_category,
    )
    .then(() => {
      showSuccessToast(toast, "Projekt erfolgreich hinzugefügt");
      emit("DataChanged");
      displayDialog.value = false;
    })
    .catch((err: Error) => showErrorToast(toast, err.message))
    .finally(() => (isLoading.value = false));
}
</script>

<style scoped>
.dialogContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--greystep);
  border-radius: 5px;
  background-color: var(--greystep);
  flex-wrap: wrap;
}

.educationInput {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

span {
  margin-bottom: 5px;
  color: var(--white);
}

.editButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: 1rem;
}

@media (max-width: 700px) {
  .dialogContainer {
    margin: 0;
    padding: 0;
  }

  .educationInput {
    margin: 0.5rem;
  }
}
</style>
