import { CalendarPublicHoliday } from "@/data-types";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { Module } from "vuex";
import { RootState } from "@/store";
import { sortPublicHolidays } from "@/utils/Helper.ts";

export interface PublicHolidayStoreState {
  publicHolidays: Map<string, CalendarPublicHoliday[]>;
}

interface ActionsPayload {
  month: string;
  year: string;
}

const publicHolidayStore: Module<PublicHolidayStoreState, RootState> = {
  state: () => ({
    publicHolidays: new Map<string, CalendarPublicHoliday[]>(),
  }),
  getters: {
    getPublicHolidays: (state): Map<string, CalendarPublicHoliday[]> => state.publicHolidays,
  },
  actions: {
    async fetchPublicHolidays({ state }, { year, month }: ActionsPayload) {
      const key = month + year;
      if (state.publicHolidays.has(key)) return;
      const holidays = await erpnextApi.getPublicHolidays(month, year);
      state.publicHolidays.set(key, sortPublicHolidays(holidays));
    },
  },
};

export default publicHolidayStore;
