<template>
  <BaseCard>
    <div class="title">
      <h2>Organigram</h2>
      <div class="chart-buttons">
        <Button type="button" label="Funktionen >" aria-haspopup="true" aria-controls="overlay_tmenu" @click="toggle" />
        <TieredMenu id="overlay_tmenu" ref="menu" :model="items" popup />
      </div>
    </div>
    <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
    <div ref="chartContainer" />
  </BaseCard>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { OrgChart } from "d3-org-chart";
import { HierarchyNode } from "d3";
import BaseCard from "@/ui/BaseCard.vue";
import { ChartNode } from "@/data-types";
import { showErrorToast } from "@/utils/ToastService.ts";
import { useToast } from "primevue/usetoast";
import getErpOrganisationChart from "@/features/organigram/utils/Chart";
import store from "@/store";
import TieredMenu from "primevue/tieredmenu";

const isLoading = ref(true);
let organisationChart: ChartNode[];
const toast = useToast();
const chartContainer = ref<HTMLDivElement>();
const chart: OrgChart<ChartNode> = new OrgChart<ChartNode>();
const menu = ref<TieredMenu>();
const items = ref<
  (
    | { icon: string; label: string; command: () => OrgChart<ChartNode> | void }
    | {
        separator: boolean;
      }
  )[]
>([
  {
    label: "Kompakt",
    icon: "pi pi-ellipsis-v",
    command: () => chart.compact(true).render(),
  },
  {
    label: "Horizontal",
    icon: "pi pi-ellipsis-h",
    command: () => chart.compact(false).render(),
  },
  {
    label: "Alle zuklappen",
    icon: "pi pi-angle-double-up",
    command: () => chart.collapseAll().render(),
  },
  {
    label: "Alle aufklappen",
    icon: "pi pi-angle-double-down",
    command: () => chart.expandAll().render(),
  },
  {
    separator: true,
  },
  {
    label: "Fullscreen",
    icon: "pi pi-arrows-alt",
    command: () => chart.fullscreen(),
  },
  {
    label: "Zentrieren",
    icon: "pi pi-map-marker",
    command: () => chart.fit(),
  },
  {
    separator: true,
  },
  {
    label: "Exportieren",
    icon: "pi pi-file-export",
    command: () => {
      chart.downloadImage({
        node: document.querySelector("svg") as SVGElement,
        imageName: getToday() + "-organigramm",
      });
    },
  },
]);

onMounted(async () => {
  await store.dispatch("fetchAllEmployees");
  await calculateOrganisationChartData();
});

onBeforeUnmount(() => {
  destroyChart();
});

const toggle = (event: MouseEvent | PointerEvent) => {
  if (menu.value) menu.value.toggle(event);
};

const destroyChart = () => {
  if (organisationChart) chart.clear();
};

async function calculateOrganisationChartData() {
  try {
    organisationChart = await getErpOrganisationChart();
    generateOrgChart(organisationChart);
  } catch (e: any) {
    showErrorToast(toast, e.message);
  } finally {
    isLoading.value = false;
  }
}

function generateOrgChart(data: ChartNode[] | undefined) {
  if (!data || !chartContainer.value) return;
  chart
    .container(chartContainer.value as unknown as string)
    .svgHeight(window.innerHeight - 300)
    .nodeHeight((d: HierarchyNode<ChartNode>) => d.data.height)
    .nodeWidth(() => 180)
    .compact(true)
    .initialZoom(1.0)
    .childrenMargin(() => 40)
    .compactMarginBetween(() => 15)
    .compactMarginPair(() => 80)
    .nodeContent(function (d: HierarchyNode<ChartNode>) {
      return `
              <div style="
                display: flex;
                flex-direction: column;
                row-gap: 4px;
                justify-content: center;
                align-items: center;
                border: 1px solid #acb509; /* use the direct color code, else the export won't work */
                background-color: var(--dark);
                border-radius: 4px;
                overflow:visible;
                text-align: center;
                height: ${d.data.height}px;
                width: 180px;">

                    <div style="font-weight: bold; color: var(--fontColorGrey)">
                        ${d.data.positionName}</div>
                    <div style="font-style: italic; color: var(--fontColorLight)">
                        ${d.data.name}
                    </div>

              </div>`;
    })
    .data(data)
    .render();
}

function getToday() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
</script>

<style scoped>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-buttons {
  border-radius: 2px;
  border: 1px solid #9caeb7;
  display: inline-flex;
  gap: 4px;
  padding: 2px;
  max-height: 36px;
}
</style>
