<template>
  <BaseCard>
    <DataTable
      :value="generatedVoucherTableData"
      paginator
      :rows="5"
      :rows-per-page-options="[5, 10, 20, 50]"
      paginator-template="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      current-page-report-template="{first} bis {last} von {totalRecords}"
      :loading="isLoading"
      sort-field="date"
      :sort-order="-1"
    >
      <Column sortable field="date" header="Datum">
        <template #body="slotProps">
          {{ formatDateStringToGerman((slotProps.data as UsedVoucher).date) }}
        </template>
      </Column>
      <Column sortable field="type" header="Typ">
        <template #body="slotProps">
          {{ voucherTypeLabels[(slotProps.data as UsedVoucher).type] }}
        </template>
      </Column>
      <Column sortable field="reason" header="Grund"></Column>
      <template #empty>Keine Voucher erstellt.</template>
    </DataTable>
  </BaseCard>
</template>

<script setup lang="ts">
import { formatDateStringToGerman } from "@/utils/Helper.ts";
import { onMounted, watch } from "vue";
import { useGeneratedVoucherTable } from "@/features/voucher/composables/UseGeneratedVoucherTable.ts";
import BaseCard from "@/ui/BaseCard.vue";
import { UsedVoucher } from "@/data-types.ts";

interface GeneratedVoucherTableProps {
  parentIsLoading: boolean;
}

const props = defineProps<GeneratedVoucherTableProps>();

const { generatedVoucherTableData, voucherTypeLabels, isLoading, initGeneratedVoucherTable } = useGeneratedVoucherTable();

watch(
  () => props.parentIsLoading,
  () => {
    isLoading.value = props.parentIsLoading;
    if (!isLoading.value) initGeneratedVoucherTable();
  },
);

onMounted(initGeneratedVoucherTable);
</script>

<style scoped></style>
