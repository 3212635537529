<template>
  <Dialog v-model:visible="isDialogVisible" modal class="dialog" @hide="handleClose">
    <AbsenceBooking
      ref="holidayForm"
      :is-calendar-input-locked="true"
      :start-absence="props.startAbsence"
      :end-absence="props.endAbsence"
      @submitted="submittedRequest"
    />
  </Dialog>
</template>

<script setup lang="ts">
import AbsenceBooking from "@/features/absence/components/AbsenceBooking.vue";
import Dialog from "primevue/dialog";
import { useDialogAbsenceBooking } from "@/features/workspace/components/DialogAbsenceBooking/UseDialogAbsenceBooking.ts";

interface Props {
  startAbsence: Date;
  endAbsence: Date;
}

export interface DialogAbsenceBookingEmit {
  (e: "onCloseWithoutSubmit"): void;
}

const isDialogVisible = defineModel<boolean>("dialogVisibility", { required: true });
const props = defineProps<Props>();
const emit = defineEmits(["onCloseWithoutSubmit"]);

const { submittedRequest, handleClose } = useDialogAbsenceBooking(isDialogVisible, emit);
</script>

<style>
.dialog {
  width: 25rem;
}
</style>
