<template>
  <div class="container p-d-flex p-flex-column-reverse p-flex-lg-row">
    <div class="p-col-12 p-lg-8 p-p-0">
      <div class="card">
        <slot name="left" />
      </div>
    </div>
    <div class="p-col-12 p-lg-4 p-px-0 p-pt-0 p-pb-3 p-pl-lg-3 p-pb-lg-0">
      <div class="card">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.card {
  border-radius: 3px;
  padding: 1rem 0.75rem;
  border: 1px solid var(--cardBorderBolor);
  background-color: var(--dark);
  min-height: 25vh;
}
</style>
