<template>
  <div class="radio-group">
    <label for="holidayOption">Art der Abwesenheit</label>
    <div class="form-control">
      <div class="radio-option">
        <input id="vacation" v-model="selectedAbsenceType" type="radio" value="Urlaub" />
        <label for="vacation">Urlaub</label>
      </div>
      <div class="radio-option">
        <input id="sickness" v-model="selectedAbsenceType" type="radio" value="Krankheitsbedingte Abwesenheit" />
        <label for="sickness">Krankheitsbedingte Abwesenheit</label>
      </div>
    </div>
  </div>
  <HolidayForm
    v-if="selectedAbsenceType === 'Urlaub'"
    :is-calendar-input-locked="props.isCalendarInputLocked"
    :start-absence="props.startAbsence"
    :end-absence="props.endAbsence"
    @submitted="emit('submitted')"
  ></HolidayForm>
  <IllnessForm
    v-else
    :is-calendar-input-locked="props.isCalendarInputLocked"
    :start-absence="props.startAbsence"
    :end-absence="props.endAbsence"
    @submitted="emit('submitted')"
  ></IllnessForm>
</template>

<script setup lang="ts">
import { ref } from "vue";
import HolidayForm from "@/features/absence/components/holidayForm/HolidayForm.vue";
import IllnessForm from "@/features/absence/components/illnessForm/IllnessForm.vue";

interface Props {
  isCalendarInputLocked?: boolean;
  startAbsence?: Date | null;
  endAbsence?: Date | null;
}

const props = withDefaults(defineProps<Props>(), {
  isCalendarInputLocked: false,
  startAbsence: null,
  endAbsence: null,
});
const emit = defineEmits(["submitted"]);
const selectedAbsenceType = ref("Urlaub");
</script>

<style scoped>
h2 {
  text-align: center;
}

.form-control {
  margin: 1rem 0 0;
}

label {
  font-weight: bold;
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}

select {
  background: transparent;
  color: var(--white);
  cursor: pointer;
}

.radio-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; /* Adjust this value to increase or decrease the space */
}

.radio-option {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.radio-option input[type="radio"] {
  margin-right: 0.5rem;
}
</style>
