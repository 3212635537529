<template>
  <BaseCardWeekly>
    <div class="dayColumnWithoutBorder dayColumnDescription">
      <div class="headerBox noBorder"><br /></div>
      <!--Labels left-->
      <div v-for="(item, index) in header" :key="index" class="labelBox">
        {{ item.name }}
      </div>
      <div v-for="bookingPosition in shownPositions" :key="bookingPosition.description">
        <div
          v-if="canChangeBooking"
          class="labelBox projectBox"
          :style="{
            fontStyle: bookingPosition.closed || !bookingPosition.isUserAllowedToBook ? 'italic' : 'normal',
            color: bookingPosition.closed || !bookingPosition.isUserAllowedToBook ? 'grey' : 'inherit',
          }"
        >
          {{ bookingPosition.project }}<br />
          {{ bookingPosition.description }}<br />
        </div>
      </div>
      <div v-if="!isExternal && canChangeBooking" class="labelBox">
        <p>Stimmung</p>
      </div>
      <div v-if="canChangeBooking" class="labelBox">
        <p>Notizen</p>
      </div>
      <Dropdown
        v-if="canChangeBooking"
        v-model="positionToAdd"
        class="dropdownStyling"
        :class="{ 'empty-list': shownPositions.length === 0 }"
        :options="positionsToAdd"
        option-label="full_name"
        placeholder="Position hinzufügen"
        @change="addingBPClicked"
        @focus="disableScroll"
        @blur="closeDropdown"
      />
    </div>

    <ScrollPanel class="mobileScrollPanel">
      <div class="p-d-flex">
        <!--Day Columns-->
        <ConfirmDialog></ConfirmDialog>
        <div v-for="index in WeekLength" :key="index" class="dayColumn">
          <DayColumn
            :comments="comments"
            :work-day-info="days[index - 1]"
            :booking-positions="bookingPositions"
            :user-profile="userProfile"
            :days="days"
            @day-changed="updateWeeklyTotal"
            @positions-from-day-column="changePositionsToAdd"
          ></DayColumn>
        </div>
        <div style="margin-top: 1rem">
          <Button id="expandButton" :icon="expandButtonText" @click="toggleFullWeek"></Button>
        </div>
        <div class="dayColumnWithoutBorder">
          <WeeklyTotal :key="updateWeeklyTotalKey" :days="days" :is-project-showing="canChangeBooking"></WeeklyTotal>
        </div>
      </div>
    </ScrollPanel>
  </BaseCardWeekly>
</template>

<script setup lang="ts">
import { BookingDay, BookingDayPosition, BookingDayPositionWithComment, BookingPositionDescription, erpNextUserProfile } from "@/data-types";
import BaseCardWeekly from "@/ui/BaseCardWeekly.vue";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import ScrollPanel from "primevue/scrollpanel";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import DayColumn from "./DayColumn.vue";
import WeeklyTotal from "./WeeklyTotal.vue";
import ConfirmDialog from "primevue/confirmdialog";
import { isBookingDayRelevant } from "@/features/weekly/utils/Weekly.ts";
import { evaluateBookingPositionsForCurrentWeek } from "@/features/weekly/utils/WeeklyTable.ts";

interface Props {
  days: BookingDay[];
  bookingPositions: Map<string, BookingPositionDescription>;
  bookingData: Map<string, BookingDay>;
}

const props = defineProps<Props>();
const emit = defineEmits<{ updateMonthlyTotal: [void] }>();
const store = useStore();
const positionsToAdd = ref<BookingPositionDescription[]>([]);
const positionToAdd = ref({ name: "" });
const comments = ref<BookingDayPositionWithComment[]>([]);
const canChangeBooking = computed<boolean>(() => props.days.some((day: BookingDay) => isBookingDayRelevant(day)));
const userProfile = computed((): erpNextUserProfile => store.getters.getUserProfile);
//WeeklyTotal-Component has this key and reloads if it gets incremented
const updateWeeklyTotalKey = ref(0);
const isExternal = computed(() => store.getters.isExternal);
//gets called by an emit of the dayColumn
const updateWeeklyTotal = () => {
  emit("updateMonthlyTotal");

  props.days.forEach((day) => {
    day.positionHours.forEach((pos) => {
      if (pos.comment) {
        comments.value.push({ comment: pos.comment, name: pos.name });
      }
    });
  });

  comments.value = getUniqueComments(comments.value);
  updateWeeklyTotalKey.value++;
};

function getUniqueComments(comments: BookingDayPositionWithComment[]) {
  const uniqueComments: BookingDayPositionWithComment[] = [];
  const seenComments = new Set<string>();

  for (const comment of comments) {
    const key = `${comment.comment}-${comment.name}`;
    if (!seenComments.has(key)) {
      seenComments.add(key);
      uniqueComments.push(comment);
    }
  }
  return uniqueComments;
}

const header = [
  { name: "Start", v: "start" },
  { name: "Pause", v: "pause" },
  { name: "Ende", v: "end" },
  { name: "Arbeitszeit", v: "duration" },
];

const WeekLength = ref(5);
const expandButtonText = ref("pi pi-angle-right");

//check if table should be expanded from beginning
props.days[props.days.length - 1].positionHours.forEach((positionHour: BookingDayPosition) => {
  if (positionHour.hours !== "00:00") WeekLength.value = 7;
});
props.days[props.days.length - 2].positionHours.forEach((positionHour: BookingDayPosition) => {
  if (positionHour.hours !== "00:00") WeekLength.value = 7;
});

const toggleFullWeek = () => {
  if (WeekLength.value == 5) {
    WeekLength.value = 7;
    expandButtonText.value = "pi pi-angle-left";
  } else {
    WeekLength.value = 5;
    expandButtonText.value = "pi pi-angle-right";
  }
};

function addingBPClicked() {
  props.days.forEach((day: BookingDay) => {
    day.positionHours.push({
      hours: "00:00",
      name: positionToAdd.value.name,
      comment: "",
    });
  });

  positionsToAdd.value = positionsToAdd.value.filter((e) => e.name !== positionToAdd.value.name);
  getShownPositions();
}

function closeDropdown() {
  document.body.classList.remove("no-scroll");
}

function disableScroll() {
  document.body.classList.add("no-scroll");
}

const changePositionsToAdd = (positions: any) => {
  positionsToAdd.value = positionsToAdd.value.filter((e) => e.name !== positions);
};

function getShownPositions() {
  const result: BookingPositionDescription[] = [];
  for (const pos of props.days[0].positionHours) {
    const bookingPosition = props.bookingPositions.get(pos.name);
    const changeableTill = bookingPosition ? bookingPosition.changeable_till : null;
    const currentDate = props.days[0].date;
    if (changeableTill === null || currentDate! <= new Date(changeableTill)) {
      result.push(
        bookingPosition ||
          ({
            name: pos.name,
            description: "Loading...",
            project: "",
            number: "",
            has_comment: false,
          } as BookingPositionDescription),
      );
    }
  }
  return result;
}

const shownPositions = computed(getShownPositions);

onMounted(() => {
  props.bookingData.forEach((bookingDay) =>
    bookingDay.positionHours.forEach((bookingDayPosition) => {
      if (bookingDayPosition.comment) {
        comments.value.push({
          comment: bookingDayPosition.comment,
          name: bookingDayPosition.name,
        });
      }
    }),
  );

  positionsToAdd.value = evaluateBookingPositionsForCurrentWeek(props.bookingPositions, props.days[0], props.days[5]);
});
</script>

<style scoped>
#expandButton {
  height: 100%;
  width: 0.7rem;
  padding: 0;
  background-color: var(--cardBorderBolor);
  border-color: var(--cardBorderBolor);
  color: white;
}

.dropdownStyling {
  margin-top: 3.5rem;
  background-color: var(--tableBackgroundHighlight);
  color: white;
}

.empty-list {
  margin-top: 5rem;
}

.mobileScrollPanel {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 1286px) {
  .mobileScrollPanel {
    width: auto;
  }
}

@media (max-width: 767px) {
  .dayColumnDescription {
    margin-left: 1rem;
  }

  .mobileScrollPanel {
    width: calc(100vw - 0rem);
  }
}

@media (min-width: 768px) {
}
</style>

<!--suppress CssUnusedSymbol -->
<style>
.p-scrollpanel-content {
  width: unset;
  height: unset;
  overflow-x: hidden !important;
  overflow-y: hidden;
  padding: 0 18px 0 0;
}

@media (max-width: 1286px) {
  .p-scrollpanel-content {
    overflow-x: auto !important;
  }
}

.p-scrollpanel-wrapper {
  height: unset;
}

/* Verhindert das Scrollen auf dem body-Element */
.no-scroll {
  overflow: hidden;
}
</style>
