import erpnextApi from "@/rest/ErpnextApi.ts";
import { VisitorTableData } from "@/features/visitor/VisitorView.vue";
import { ToastServiceMethods } from "primevue/toastservice";
import { showErrorToast } from "@/utils/ToastService.ts";

const calculateSecondsToMilliseconds = (seconds: number) => (seconds <= 0 ? 0 : seconds * 1000),
  calculateMinutesToMilliseconds = (minutes: number) => (minutes <= 0 ? 0 : minutes * calculateSecondsToMilliseconds(60)),
  calculateHoursToMilliseconds = (hours: number) => (hours <= 0 ? 0 : hours * calculateMinutesToMilliseconds(60)),
  calculateDaysToMilliseconds = (days: number) => (days <= 0 ? 0 : days * calculateHoursToMilliseconds(24));

export const dateRange = () => ({
  minDate: new Date(new Date().setHours(0, 0, 0, 0)),
  maxDate: new Date(),
});

export const convertDateToGerTimeString = (date: Date | number): string =>
  new Date(date).toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

export const convertDateToGerDateString = (date: Date | number): string =>
  new Date(date).toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

export const currentDate = () => new Date(new Date().setSeconds(0, 0));

export const convertDateStringToDate = (date: string | Date): Date => new Date(date);

export function setEmptyStringsInObjectToUndefined<T>(object: T) {
  for (const key in object) {
    if (typeof object[key] === "string" && !object[key]) {
      (object as any)[key] = undefined;
    }
  }
  return object;
}

const isDateTimeGEMinDate = (dateTime: number, entryToConfirmDate: Date | string) => dateTime >= calculateMinDate(entryToConfirmDate).getTime();

const isDateTimeLECurrentDateTime = (dateTime: number) => dateTime <= new Date().getTime();

export function isCurrentDateInRange(currentDate: Date | number | undefined, entryToConfirmDate: Date | string | undefined) {
  if (!currentDate || !entryToConfirmDate) return false;
  const currentDateTime = new Date(currentDate).getTime();
  const isDateInUpperBound = isDateTimeGEMinDate(currentDateTime, entryToConfirmDate);
  const isDateInLowerBound = isDateTimeLECurrentDateTime(currentDateTime);
  return isDateInUpperBound && isDateInLowerBound;
}

export function calculateMinDate(date: Date | string) {
  if (typeof date === "string") date = new Date(date);
  return date.getTime() < dateRange().minDate.getTime() ? dateRange().minDate : date;
}

export function formatToLocalDate(date: Date | string | undefined) {
  if (!date) return undefined;
  if (typeof date === "string") date = convertDateStringToDate(date);
  const pad = (number: number) => (number < 10 ? "0" + number : number);

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export const exportedForTesting = {
  calculateDaysToMilliseconds,
  calculateHoursToMilliseconds,
  calculateMinutesToMilliseconds,
  calculateSecondsToMilliseconds,
};

export async function sendVisitorCodeAsMail(visitorTableData: VisitorTableData, toastServiceMethods: ToastServiceMethods): Promise<void> {
  visitorTableData.isEmailSendLoading = true;
  try {
    await erpnextApi.sendVisitorCodeAsMail(visitorTableData.erpNextVisitorLog.name);
    visitorTableData.erpNextVisitorLog.email_send = true;
  } catch (e: any) {
    showErrorToast(toastServiceMethods, e.message);
  } finally {
    visitorTableData.isEmailSendLoading = false;
  }
}
