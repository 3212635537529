<template>
  <BaseCard>
    <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
    <div v-if="activeLend && !isLoading" class="infoHeader">Erhaltene Artikel</div>
    <DataTable v-if="activeLend" scrollable :value="activeLend">
      <Column field="item_name" header="Typ" />
      <Column field="item_description" header="Artikel" />
      <Column field="item_serial" header="Nummer / ID" />
      <Column field="issue_transaction_date" header="Ausgabedatum">
        <template #body="slotProps">
          {{ formatDateStringToGerman(formatDateToERP(slotProps.data.issue_transaction_date)) }}
        </template>
      </Column>
      <Column field="issue_confirmed_by" header="Bestätigt von">
        <template #body="slotProps">
          <Button
            v-if="!checkForConfirmation(slotProps.data.issue_confirmed_by, slotProps.data.issue_confirmed_at)"
            @click="
              openConfirmValidation = true;
              setEntryToConfirm(slotProps.data.issue_name);
            "
          >
            Bestätigen
          </Button>
          <template v-if="checkForConfirmation(slotProps.data.issue_confirmed_by, slotProps.data.issue_confirmed_at)">
            {{ getEmployeeNameFromIdArray(slotProps.data.issue_confirmed_by, employees) }}
          </template>
        </template>
      </Column>
      <Column field="issue_confirmed_at" header="Bestätigungsdatum">
        <template #body="slotProps">
          <template v-if="slotProps.data.issue_confirmed_at">
            {{ formatDateStringToGerman(formatDateToERP(slotProps.data.issue_confirmed_at)) }}
          </template>
        </template>
      </Column>
    </DataTable>

    <base-pop-up v-if="openConfirmValidation" :pop-up-message="confirmValidationMessage">
      <Button label="Ja!" style="margin-right: 1rem" @click="confirmInventoryItem()" />
      <Button label="Nein!" @click="openConfirmValidation = false" />
    </base-pop-up>

    <div v-if="returnedLend.length > 0 && !isLoading" class="returnedHeader">Zurückgegebene Artikel</div>
    <DataTable v-if="returnedLend.length > 0" scrollable :value="returnedLend">
      <Column field="item_name" header="Typ" />
      <Column field="item_description" header="Artikel" />
      <Column field="item_serial" header="Nummer / ID" />
      <Column field="return_transaction_date" header="Rückgabedatum">
        <template #body="slotProps">
          {{ formatDateStringToGerman(formatDateToERP(slotProps.data.return_transaction_date)) }}
        </template>
      </Column>
      <Column field="return_confirmed_by" header="Bestätigt von">
        <template #body="slotProps">
          <template v-if="checkForConfirmation(slotProps.data.return_confirmed_by, slotProps.data.return_confirmed_at)">
            {{ getEmployeeNameFromIdArray(slotProps.data.return_confirmed_by, employees) }}
          </template>
        </template>
      </Column>
      <Column field="return_confirmed_at" header="Bestätigungsdatum">
        <template #body="slotProps">
          <template v-if="slotProps.data.return_confirmed_at">
            {{ formatDateStringToGerman(formatDateToERP(slotProps.data.return_confirmed_at)) }}
          </template>
        </template>
      </Column>
    </DataTable>
  </BaseCard>
</template>

<script setup lang="ts">
import BaseCard from "@/ui/BaseCard.vue";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { erpNextInventory } from "@/data-types";
import { formatDateStringToGerman, formatDateToERP } from "@/utils/Helper";
import { useToast } from "primevue/usetoast";
import store from "@/store";
import { getEmployeeNameFromIdArray } from "@/utils/Todo";
import BasePopUp from "@/ui/BasePopUp.vue";
import DataTable from "primevue/datatable";
import { computed, onMounted, ref } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import Column from "primevue/column";
import Button from "primevue/button";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService.ts";

const toast = useToast();
const inventoryAPIData = ref<erpNextInventory[]>();
const inventoryData = ref<erpNextInventory[]>();
const isLoading = ref(true);
const noEntries = ref(false);
const openConfirmValidation = ref(false);
const confirmValidationMessage = "Bist du sicher dass du diesen Artikel gegenzeichnen willst?";
const entryToConfirm = ref();
const employees = computed(() => store.getters.getAllEmployeesAsMap);
const activeLend = ref<erpNextInventory[]>();
const returnedLend = ref<erpNextInventory[]>([]);

function checkForConfirmation(confirmed_by: string, confirmed_at: Date) {
  return !!(confirmed_by && confirmed_at);
}

onMounted(async () => {
  await store.dispatch("fetchAllEmployees");
});

function getInventory() {
  isLoading.value = true;
  erpnextApi
    .getInventory()
    .then((res: erpNextInventory[]) => {
      inventoryAPIData.value = res;
      inventoryData.value = inventoryAPIData.value;
      if (inventoryData.value.length === 0) {
        noEntries.value = true;
      }
      const splitArrays = inventoryData.value.reduce(
        (acc, item) => {
          if (item.return_transaction_date === null) {
            acc.activeLends.push(item);
          } else {
            acc.returnedLends.push(item);
          }
          return acc;
        },
        { activeLends: [] as erpNextInventory[], returnedLends: [] as erpNextInventory[] },
      );
      activeLend.value = splitArrays.activeLends;
      returnedLend.value = splitArrays.returnedLends;
      isLoading.value = false;
    })
    .catch((err: Error) => showErrorToast(toast, err.message));
}

function setEntryToConfirm(entry: any) {
  entryToConfirm.value = entry;
}

getInventory();

async function confirmInventoryItem() {
  isLoading.value = true;
  openConfirmValidation.value = false;
  await erpnextApi
    .confirmInventoryItem(entryToConfirm.value)
    .then(() => {
      showSuccessToast(toast, "Gegenstand erfolgreich Gegengezeichnet");
      getInventory();
    })
    .catch((err: Error) => showErrorToast(toast, err.message))
    .finally(() => (isLoading.value = false));
}
</script>

<style scoped>
.infoHeader {
  font-size: 18px;
  margin-bottom: 10px;
}

.returnedHeader {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 4rem;
}

.signButton {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 170px;
  border-left: 0.5px solid grey;
  border-bottom: 0.5px solid grey;
  background-color: var(--greystep);
}
</style>
