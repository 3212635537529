import { createStore, Module } from "vuex";
import holidayStore, { HolidayStoreState } from "@/store/modules/holiday";
import workplaceStore, { WorkplaceStoreState } from "@/store/modules/workplace";
import employeeStore, { EmployeeStoreState } from "@/store/modules/employees";
import { Credentials, Employee } from "@/data-types";
import userProfileStore, { UserProfileStoreState } from "@/store/modules/userProfile";
import startPageStore, { StartPageStoreState } from "@/store/modules/startPage";
import erpnext from "@/rest/ErpnextApi.ts";
import publicHolidayStore, { PublicHolidayStoreState } from "@/store/modules/publicHoliday";

export interface AppStateModel {
  loggedInUser: Employee | null;
}

export const mutations = {
  setLoggedInUser(state: AppStateModel, user: Employee | null) {
    if (user) user.full_name = user.first_name + " " + user.last_name;
    state.loggedInUser = user;
  },
};

export const getters = {
  isLoggedIn: (state: AppStateModel) => state.loggedInUser !== null,
  isExternal: (state: AppStateModel) => state.loggedInUser?.is_external,
  getEmployeesName: (state: AppStateModel) => (state.loggedInUser ? state.loggedInUser.name : ""),
  getEmployee: (state: AppStateModel) => state.loggedInUser,
};

export const actions = {
  async doLogin(context: any, credentials: Credentials): Promise<boolean> {
    try {
      await erpnext.login(credentials.username, credentials.password);
      const employee: Employee | null = await erpnext.getEmployee();
      context.commit("setLoggedInUser", employee);
      return true;
    } catch (e) {
      context.commit("setLoggedInUser", null);
      return false;
    }
  },
};

const appStateModel: Module<AppStateModel, RootState> = {
  state: () => ({ loggedInUser: null }),
  mutations,
  actions,
  getters,
};

export interface RootState {
  appStateModel: AppStateModel;
  holidayStore: HolidayStoreState;
  workplaceStore: WorkplaceStoreState;
  employeeStore: EmployeeStoreState;
  publicHolidayStore: PublicHolidayStoreState;
  userProfileStore: UserProfileStoreState;
  startPageStore: StartPageStoreState;
}

export default createStore<RootState>({
  modules: {
    appStateModel,
    holidayStore,
    workplaceStore,
    employeeStore,
    publicHolidayStore,
    userProfileStore,
    startPageStore,
  },
});
