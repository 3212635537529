import { UsedVoucher, VoucherType } from "@/data-types.ts";
import { ref } from "vue";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { showErrorToast } from "@/utils/ToastService.ts";
import { useToast } from "primevue/usetoast";

export function useGeneratedVoucherTable() {
  const toast = useToast();
  const isLoading = ref(false);
  const voucherTypeLabels = {
    [VoucherType.BYOD]: "BYOD",
    [VoucherType.GUEST]: "Gast",
  };

  const generatedVoucherTableData = ref<UsedVoucher[]>([]);

  async function fetchUsedVoucherCodes() {
    generatedVoucherTableData.value = await erpnextApi.getUsedVoucherCodes();
  }

  function handleFetchError(error: unknown): void {
    const errorMessage = (error as Error)?.message || "Ein unbekannter Fehler ist aufgetreten";
    showErrorToast(toast, errorMessage);
  }

  async function initGeneratedVoucherTable() {
    try {
      isLoading.value = true;
      await fetchUsedVoucherCodes();
    } catch (error) {
      handleFetchError(error);
    } finally {
      isLoading.value = false;
    }
  }

  return {
    generatedVoucherTableData,
    isLoading,
    voucherTypeLabels,
    initGeneratedVoucherTable,
  };
}
