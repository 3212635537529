<template>
  <DataTable
    v-model:filters="filter"
    :value="filteredReport"
    responsive-layout="scroll"
    class="p-datatable-sm"
    sort-field="project"
    :sort-order="1"
    selection-mode="single"
    striped-rows
    row-group-mode="rowspan"
    group-rows-by="filteredReport.project"
  >
    <template #header>
      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-md-center">
        <div class="p-mb-3 p-mb-md-0">Buchungspositionen in Stunden</div>
        <InputText v-model="filter.name.value" class="p-col-12 p-md-4" placeholder="Filter" @keydown.esc="filter.name.value = null" />
      </div>
    </template>
    <Column field="project" header="Projekt">
      <template #body="slotProps">
        <div class="p-text-nowrap">{{ slotProps.data.project }}</div>
      </template>
    </Column>
    <Column field="name" header="Buchungsposition">
      <template #body="slotProps">
        <div :class="{ 'p-text-nowrap': true, sumClass: slotProps.data.name === 'Summe' }">
          {{ slotProps.data.name }}
        </div>
      </template>
    </Column>
    <Column v-for="month in months" :key="month" :field="month" :header="month" style="min-width: 4.5rem">
      <template #body="slotProps">
        <div v-if="slotProps.data[month] !== 0" :class="{ 'p-d-flex p-jc-end': true, sumClass: slotProps.data.name === 'Summe' }">
          {{ slotProps.data[month].toFixed(2) }}
        </div>
      </template>
    </Column>
    <template #empty>Keine Buchungspositionen gefunden</template>
  </DataTable>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeMount, readonly, ref } from "vue";
import { emptyYearReport } from "@/data-types";
import { yearReportKey } from "@/keys";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import { createMonthStringArray, createYearlyTable } from "@/features/yearly/utils/Yearly";
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";

const months = createMonthStringArray();
const yearReportInjected = inject(yearReportKey, {
  yearReport: readonly(ref(emptyYearReport)),
  addBookingPosition: () => undefined,
});
const yearReport = yearReportInjected.yearReport.value;
const addBookingPosition = yearReportInjected.addBookingPosition;
const filteredReport = computed(() => createYearlyTable(yearReport.booking_positions, months));

const filter = ref({ name: { value: null, matchMode: FilterMatchMode.CONTAINS } });
const sumPerMonth = ref<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

onBeforeMount(() => {
  if (!yearReport.booking_positions.some(({ name }) => name === "Summe")) {
    setSumOfBPsPerMonth();
  }
});

function setSumOfBPsPerMonth() {
  if (yearReport.booking_positions.length === 0) return;
  yearReport.booking_positions.forEach((nameMonthsObj) => {
    for (let index = 0; index < 12; index++) {
      sumPerMonth.value[index] += nameMonthsObj.months[index];
    }
  });
  addBookingPosition({ name: "Summe", months: sumPerMonth.value });
}
</script>

<style scoped>
.sumClass {
  color: var(--primary);
}
</style>
