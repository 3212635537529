<template>
  <div v-if="hasData">
    <div class="flex-layout">
      <div>
        <div class="p-text-bold" style="color: var(--white); padding-bottom: 8px">Gesamtstunden {{ actualYear }}:</div>
        <div ref="dataEntriesRef" class="data-entries-container" style="position: relative; flex: 1; min-width: 250px">
          <OverviewDataEntry :value="buildTimeStringFromHours(yearlyAnnualStatement.carry_hours)" title="Übertrag aus dem Vorjahr:" />
          <OverviewDataEntry :value="buildTimeStringFromHours(yearlyAnnualStatement.current_hours)" title="Geleistete Stunden:" />
          <OverviewDataEntry :value="buildTimeStringFromHours(yearlyAnnualStatement.sick_hours)" title="Stunden durch Krankheit:" />
          <div class="data-entry-subtext">
            <div>({{ yearlyAnnualStatement.sick_days }} Krankheitstage)</div>
          </div>
          <OverviewDataEntry
            v-if="yearlyAnnualStatement.lump_sum_correction_hours !== 0"
            :value="buildTimeStringFromHours(yearlyAnnualStatement.lump_sum_correction_hours)"
            title="Korrektur:"
          />
          <div v-if="yearlyAnnualStatement.lump_sum_correction_hours !== 0" class="data-entry-subtext">
            <div>(pauschale/studentische Arbeitspläne)</div>
          </div>
          <OverviewDataEntry :value="buildTimeStringFromHours(-yearlyAnnualStatement.payout_hours)" title="Auszahlung / Gutschrift:" />

          <div id="divider" :style="{ width: widestWidth }" style="margin-left: 190px" />
        </div>
        <OverviewDataEntry :value="buildTimeStringFromHours(totalHoursCalc)" title="" />

        <div style="margin-top: 20px">
          <div class="p-text-bold" style="color: var(--white); padding-bottom: 8px">Verbleibende Stunden {{ actualYear }}:</div>
          <div ref="dataEntriesRef" class="data-entries-container" style="position: relative; flex: 1; min-width: 250px">
            <OverviewDataEntry title="Pflichtstunden:" :value="buildTimeStringFromHours(yearlyAnnualStatement.mandatory_hours)" />
            <OverviewDataEntry :title="'Gesamtstunden ' + actualYear + ':'" :value="buildTimeStringFromHours(-totalHoursCalc)" />

            <div id="divider" :style="{ width: widestWidth }" style="margin-left: 190px" />
          </div>
        </div>
        <OverviewDataEntry :value="buildTimeStringFromHours(yearlyAnnualStatement.left_hours)" title="" />
      </div>

      <div>
        <div class="p-text-bold" style="color: var(--white); padding-bottom: 8px">Überstunden:</div>
        <div v-if="yearlyAnnualStatement.current_overtime_hours == 0">
          <div ref="dataEntriesRef" class="data-entries-container" style="position: relative; flex: 1; min-width: 250px">
            <OverviewDataEntry :value="buildTimeStringFromHours(overtimeHours)" title="Überstunden:" />
            <OverviewDataEntry
              v-if="yearlyAnnualStatement.current_overtime_hours > 0"
              :is-sub-item="true"
              :value="buildTimeStringFromHours(yearlyAnnualStatement.current_overtime_hours)"
              title="Davon Überschritten: (>1760h)"
            />
            <OverviewDataEntry
              v-if="left_carry_overtime > 0"
              :value="buildTimeStringFromHours(left_carry_overtime)"
              title="Davon aus Vorjahr:"
              :is-sub-item="true"
            />
          </div>
        </div>
        <div style="padding-top: 20px; flex: 1">
          <div class="p-field p-grid">
            <label class="p-col-fixed data-entry-label" style="color: var(--white)">Geschätzt *: </label>
            <span class="p-col-fixed data-entry-value" style="font-size: 11pt">
              {{ buildTimeStringFromHours(yearlyAnnualStatement.guessed_overtime_hours) }}
            </span>
          </div>
          <div class="data-entry-subtext" style="max-width: 240px">
            <span>* Basierend auf den bisherigen Stunden und Jahresfortschritt</span>
          </div>
        </div>
      </div>

      <div>
        <div class="p-text-bold" style="color: var(--white); padding-bottom: 8px">Verteilung Arbeitszeit:</div>
        <div v-for="(entry, index) in workPlanEntries" :key="index">
          <OverviewDataEntry :title="entry.title" :value="entry.value" />
        </div>
        <div v-if="yearlyAnnualStatement.work_plan_mode === 'Adopted'" class="data-entry-subtext p-pt-2" style="max-width: 240px">
          Die Werte werden aktuell aus dem Arbeitsplan übernommen, da dieser erst
          {{ yearlyAnnualStatement.work_plan_age }} Wochen alt ist.
        </div>
      </div>

      <div>
        <div class="p-text-bold" style="color: var(--white); padding-bottom: 8px">Arbeitsorte:</div>
        <div
          v-if="!(yearlyAnnualStatement.work_days === undefined && yearlyAnnualStatement.work_days_at_office === undefined)"
          ref="dataEntriesRef"
          class="data-entries-container"
          style="position: relative; flex: 1; min-width: 250px"
        >
          <OverviewDataEntry data-testid="yearly-annual-statement-office" title="Büro:" :value="yearlyAnnualStatement.work_days_at_office?.toString()" />
          <OverviewDataEntry data-testid="yearly-annual-statement-homeoffice" title="Homeoffice:" :value="homeofficeDays.toString()" />
        </div>
        <div v-else>
          <p>Keine Daten verfügbar</p>
        </div>
      </div>
    </div>
    <div class="details">
      <YearlyDetails />
    </div>
    <div class="p-pt-4" style="width: auto">
      <div class="form-group">
        <label id="lastUpdate" class="data-entry-subtext">Letzte Aktualisierung: {{ convertFromErpDate(yearlyAnnualStatement.last_update) }}</label>
      </div>
    </div>
  </div>
  <div v-else data-testid="yearly-annual-statement-nothing-to-show" class="p-pt-4" style="width: auto">Keine Buchungspositionen gefunden</div>
</template>

<script setup lang="ts">
import { AnnualStatement, emptyAnnualStatement } from "@/data-types";
import YearlyDetails from "@/features/yearly/components/YearlyDetails.vue";
import { annualStatementKey } from "@/keys";
import OverviewDataEntry from "@/ui/OverviewDataEntry.vue";
import { buildTimeStringFromHours, convertFromErpDate } from "@/utils/Helper";
import { computed, inject, onMounted, ref, watch } from "vue";

const dataEntriesRef = ref(null);
const widestWidth = ref("");

const annualStatementInjected = inject(annualStatementKey, ref(emptyAnnualStatement));
const yearlyAnnualStatement = computed(() => {
  return annualStatementInjected.value;
});

const hasData = computed(() => {
  return JSON.stringify(yearlyAnnualStatement.value) !== JSON.stringify(emptyAnnualStatement);
});

const actualYear = computed(() => {
  return yearlyAnnualStatement.value.annual?.toString();
});

const totalHoursCalc = computed(() => {
  return (
    -yearlyAnnualStatement.value.payout_hours +
    yearlyAnnualStatement.value.sick_hours +
    yearlyAnnualStatement.value.current_hours +
    yearlyAnnualStatement.value.carry_hours +
    yearlyAnnualStatement.value.lump_sum_correction_hours
  );
});

const left_carry_overtime = computed(() =>
  Math.max(0, Math.min(yearlyAnnualStatement.value.carry_overtime_hours, yearlyAnnualStatement.value.current_overtime_hours)),
);

const homeofficeDays = computed(() => {
  const { work_days = 0, work_days_at_office = 0 } = yearlyAnnualStatement.value;
  return work_days - work_days_at_office;
});
const workPlanDaysMapping = () => {
  const mapping = {
    "Montag:": "work_plan_monday",
    "Dienstag:": "work_plan_tuesday",
    "Mittwoch:": "work_plan_wednesday",
    "Donnerstag:": "work_plan_thursday",
    "Freitag:": "work_plan_friday",
  } as any;

  const isSaturday = yearlyAnnualStatement?.value?.work_plan_saturday ?? 0 > 0;
  const isSunday = yearlyAnnualStatement?.value?.work_plan_sunday ?? 0 > 0;
  if (isSaturday || isSunday) mapping.Samstag = "work_plan_saturday";
  if (isSunday) mapping.Sonntag = "work_plan_sunday";
  return mapping;
};

const workPlanEntries = computed(() => {
  return Object.entries(workPlanDaysMapping()).map(([germanDay, englishProp]) => {
    const hours = yearlyAnnualStatement.value[englishProp as keyof AnnualStatement];
    return {
      title: germanDay,
      value: hours ? buildTimeStringFromHours(hours) : "-",
    };
  });
});

const calculateWidestWidth = () => {
  let maxWidth = 0;
  const dataEntriesElement = dataEntriesRef.value as unknown as HTMLElement;
  const dataEntries = dataEntriesElement?.querySelectorAll(".data-entry-value") || [];
  dataEntries.forEach((entry) => {
    const width = (entry as HTMLElement).offsetWidth;
    if (width > maxWidth) {
      maxWidth = width;
    }
  });
  widestWidth.value = `${maxWidth}px`;
};

const overtimeHours = computed(() => {
  return yearlyAnnualStatement.value.current_overtime_hours + yearlyAnnualStatement.value.current_normal_hours;
});

onMounted(() => {
  calculateWidestWidth();
});

watch(
  () => yearlyAnnualStatement.value,
  () => {
    calculateWidestWidth();
  },
  { deep: true },
);
</script>

<style scoped>
.details {
  margin-top: 20px;
}

.flex-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.form-group {
  flex-basis: auto;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  background-color: transparent;
}

.data-entry-subtext {
  color: #aaa;
  font-size: smaller;
  margin-top: -5px;
  margin-bottom: 5px;
}

#divider {
  background-color: var(--white);
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 1rem;
}

.data-entries-container {
  position: relative;
}

.data-entry-label {
  width: 190px;
}

.data-entry-value {
  width: 70px;
  text-align: right;
}

.p-field {
  text-align: left;
  margin-bottom: 0;
  color: var(--white);
}
</style>
