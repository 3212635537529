import { BookingDay, BookingDayPosition, BookingDayPositionWithComment, BookingPositionDescription, Desk, WorkplaceBooking, workplaces } from "@/data-types";
import { buildTimeString, buildTimeStringFromHours, convertFromErpDate, formatDateToERP, splitTimeString } from "@/utils/Helper";
import { Reactive } from "vue";

enum Weekday {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 0,
}

export function isBookingDayInWeekend({ currentDate }: BookingDay): boolean {
  const weekday = new Date(currentDate).getDay();
  return weekday === Weekday.Saturday || weekday === Weekday.Sunday;
}

export function isCommentExisting(bookingPositions: Map<string, BookingPositionDescription>, item: BookingDayPosition) {
  return bookingPositions.get(item.name)?.has_comment ?? false;
}

export function isClosed(bookingPositions: Map<string, BookingPositionDescription>, item: BookingDayPosition) {
  return bookingPositions.get(item.name)?.closed ?? false;
}

export function getBookingPosition(bookingPositions: Map<string, BookingPositionDescription>, item: BookingDayPosition) {
  return bookingPositions.get(item.name);
}

export function calcWorkTime(day: BookingDay) {
  let totalHours = 0;
  let totalMinutes = 0;
  day.positionHours.forEach((pH: BookingDayPosition) => {
    const segs = splitTimeString(pH.hours);
    totalHours += segs.hours;
    totalMinutes += segs.minutes;
  });

  return buildTimeString(totalHours, totalMinutes);
}

export function calculateEndTime(day: BookingDay) {
  const st = splitTimeString(day.start);
  const br = splitTimeString(day.break);
  const wo = splitTimeString(calcWorkTime(day));
  return buildTimeString(st.hours + br.hours + wo.hours, st.minutes + br.minutes + wo.minutes);
}

export function convertToErpDay(day: BookingDay) {
  function getErpDate() {
    return convertFromErpDate(day.currentDate);
  }

  if (day.start === "") {
    return {
      date: getErpDate(),
      state: "Absent",
      start: "00:00",
      br: "00:00",
      end: "00:00",
      notes: "",
    };
  }

  const positions: BookingDayPosition[] = [];
  day.positionHours.forEach((position: BookingDayPosition) => {
    if (position.hours && position.hours !== "00:00") {
      const newPos = {
        name: position.name,
        hours: position.hours,
        comment: position.comment,
      };
      positions.push(newPos);
    }
  });

  return {
    date: getErpDate(),
    state: "Present",
    start: formatTimeToErp(day.start),
    br: formatTimeToErp(day.break),
    end: formatTimeToErp(day.end),
    positions: positions,
    mood: day.mood,
    notes: day.notes,
  };
}

export function disableTimeChangeInFuture(bookingPositions: Map<string, BookingPositionDescription>, item: BookingDayPosition, day: BookingDay) {
  const bookingPositionObj = bookingPositions.get(item.name);
  if (!bookingPositionObj?.changeable_till) return false;

  const changeableTill = new Date(bookingPositionObj.changeable_till);
  const columnDate = new Date(day.currentDate);
  return columnDate > changeableTill;
}

export function disableTimeChangeInPast(bookingPositions: Map<string, BookingPositionDescription>, item: BookingDayPosition, day: BookingDay) {
  const bookingPositionObj = bookingPositions.get(item.name);
  if (!bookingPositionObj?.changeable_from) return false;
  const changeableFrom = new Date(bookingPositionObj.changeable_from);
  const columnDate = new Date(day.currentDate);
  return columnDate < changeableFrom;
}

export function isDayResettable(bookingPositionDescriptionsMap: Map<string, BookingPositionDescription>, day: BookingDay): boolean {
  const isStartTimeSet = !!day.start;
  const isDayNotBooked = !day.booked;

  const isBookingPositionResettable = (bookingPosition: BookingDayPosition): boolean => {
    const isHoursZero = bookingPosition.hours === "00:00";
    const isBookingPositionChecked =
      disableTimeChangeInFuture(bookingPositionDescriptionsMap, bookingPosition, day) ||
      disableTimeChangeInPast(bookingPositionDescriptionsMap, bookingPosition, day);

    const isBookingPositionClosed = isClosed(bookingPositionDescriptionsMap, bookingPosition);
    if (isBookingPositionClosed || isBookingPositionChecked) {
      return isHoursZero;
    }
    return true;
  };
  const areAllBookingPositionsResettable = day.positionHours.every(isBookingPositionResettable);
  return isDayNotBooked && isStartTimeSet && areAllBookingPositionsResettable;
}

export function isBookingPositionChangeable(
  bookingPositionDescriptionsByName: Map<string, BookingPositionDescription>,
  bookingDayPosition: BookingDayPosition,
  day: BookingDay,
) {
  const isBookingPositionUnchecked = !(
    disableTimeChangeInFuture(bookingPositionDescriptionsByName, bookingDayPosition, day) ||
    disableTimeChangeInPast(bookingPositionDescriptionsByName, bookingDayPosition, day)
  );
  const isBookingPositionOpen = !isClosed(bookingPositionDescriptionsByName, bookingDayPosition);

  const isBookingPositionBookable = isBookingPositionUnchecked && isBookingPositionOpen;

  const isHoursNotZero = bookingDayPosition.hours !== "00:00";

  if (isBookingPositionBookable) {
    return isHoursNotZero;
  }
  return false;
}

export function resetBookingDayReactive(bookingDay: Reactive<BookingDay>) {
  bookingDay.start = "";
  bookingDay.state = "Absent";
  bookingDay.break = "00:00";
  bookingDay.end = undefined;
  bookingDay.positionHours.forEach((pH: BookingDayPosition) => (pH.hours = "00:00"));
  bookingDay.totalWorkHours = 0;
  bookingDay.mood = undefined;
  bookingDay.notes = "";
}

export function getWorkplaceDescription(
  workplaceBookings: WorkplaceBooking[],
  day: BookingDay,
  desks: Map<string, Desk>,
  isExternal: boolean,
  transportationIcon: string,
) {
  const workplaceBooking = workplaceBookings?.find((wp: WorkplaceBooking) => formatDateToERP(wp.date) == day.currentDate);
  if (workplaceBooking) {
    const wp = workplaces.find((x) => x.code === workplaceBooking.workplace);
    if (wp) {
      if (wp.code === "office") {
        const _desk = workplaceBooking.desk && desks.get(workplaceBooking.desk);
        const name = _desk ? " - " + _desk.title.replace("Tisch ", "") : "";
        const transportation = isExternal ? "" : " " + (transportationIcon ?? "⁉️");
        return wp.name + name + transportation;
      } else return wp.name;
    }
  }

  if (day.state === "Absent" || day.state === "On Leave" || day.state === "Sick") return "Abwesend";
  if (day.booked) return "Homeoffice";
  if (day.state === "Future") return "";
  return "Nicht angegeben";
}

// Helper.ts
export function addTimeStringsTogether(timeString1: string, timeString2: string) {
  const [firstHours, firstMinutes] = timeString1.split(":").map(Number);
  const [secondHours, secondMinutes] = timeString2.split(":").map(Number);

  const totalHours = firstHours + secondHours;
  const totalMinutes = firstMinutes + secondMinutes;

  const hours = totalHours + Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const combinedTimeString = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;

  return combinedTimeString;
}

export function formatTimeToErp(time: string | undefined) {
  if (!time || !time.includes(":")) return buildTimeStringFromHours(null);
  const segments = splitTimeString(time);
  return buildTimeString(segments.hours, segments.minutes);
}

export function getHoursFromTimeString(timeText: string | undefined) {
  if (!timeText) return 0;
  const segments = splitTimeString(timeText);
  return segments.hours + segments.minutes / 60.0;
}

export function filterComments(
  query: string,
  bookingDayPosition: BookingDayPosition,
  bookingDayPositionWithComment: BookingDayPositionWithComment[],
): string[] {
  return [
    ...new Set(
      bookingDayPositionWithComment
        .filter(({ name, comment }) => name === bookingDayPosition.name && comment.toLowerCase().startsWith(query.toLowerCase()))
        .map(({ comment }) => comment),
    ),
  ];
}

export function isDecreaseBookingPositionShownForBreak(isOverbookedTime: boolean, dayEnd: string | undefined): boolean {
  return isOverbookedTime && dayEnd !== "00:00" && dayEnd !== undefined;
}

export function isDecreaseBookingPositionEnabledForBookingPosition(isOverbookedTime: boolean, bookingDayPositionHours: string): boolean {
  return isOverbookedTime && bookingDayPositionHours !== "00:00";
}

export function isMinimumBreakTimeTaken(rules: any, day: BookingDay, endTimeDiff: number | undefined) {
  if (!day.break) return false;
  if (!endTimeDiff) return false;
  const newBreakTime = calculateNewTime(day.break, endTimeDiff);
  return rules.break.missingBreak.$validator(newBreakTime, day).$valid;
}

export function calculateNewTime(timeText: string, endTimeDiff: number): string {
  const timeAsNumber = getHoursFromTimeString(timeText) + endTimeDiff;
  const timeAsDate = new Date(0, 0);
  if (timeAsNumber < 0) {
    timeAsDate.setMinutes(0);
    timeAsDate.setHours(0);
  } else {
    timeAsDate.setMinutes(timeAsNumber * 60);
    timeAsDate.setHours(timeAsNumber);
  }
  return timeAsDate.toTimeString().slice(0, 5);
}
