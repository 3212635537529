<template>
  <div class="form-control">
    <label for="date">Zeitraum</label>
    <Calendar
      id="date"
      v-model="validation.dateRange.$model"
      :disabled="isLoading || isLocked"
      class="full-width"
      date-format="dd.mm.yy"
      selection-mode="range"
      :manual-input="false"
      placeholder="Gebe einen Zeitraum ein"
      :class="{ 'p-invalid': validation.dateRange.$invalid && submitted }"
      @month-change="onMonthChange"
    >
      <template #date="slotProps">
        <strong v-if="currentPublicHolidays.find((publicHoliday) => isPublicHoliday(publicHoliday, slotProps.date.day))" class="calendarPublicHoliday">
          {{ slotProps.date.day }}
        </strong>
        <strong v-else-if="currentPublicHolidays.find((publicHoliday) => isWeekend(publicHoliday, slotProps.date.day))" class="calendarWeekend">
          {{ slotProps.date.day }}
        </strong>
        <template v-else>
          {{ slotProps.date.day }}
        </template>
      </template>
    </Calendar>
    <small v-if="validation.dateRange.$invalid && submitted" class="p-error">
      {{ validation.dateRange.required.$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from "vue";
import { CalendarPublicHoliday } from "@/data-types";
import { getMonthFromDate, getYearFromDate } from "@/utils/Helper";
import Calendar, { CalendarMonthChangeEvent } from "primevue/calendar";
import { useStore } from "vuex";
import { isPublicHoliday, isWeekend } from "@/utils/HolidayNameGrabber";
import { padNumberWithZero } from "@/utils/UiUtils.ts";

interface Props {
  submitted: boolean;
  isLoading: boolean;
  isLocked?: boolean;
}

withDefaults(defineProps<Props>(), {
  submitted: false,
  isLoading: false,
  isLocked: false,
});
const validation = defineModel<any>("validation", { required: true });
const store = useStore();
const today = new Date();

const publicHolidays = computed(() => store.getters.getPublicHolidays);
const currentPublicHolidays = ref<CalendarPublicHoliday[]>([]);
const currentRange = reactive({
  month: getMonthFromDate(today),
  year: getYearFromDate(today),
});

onMounted(() => fetchCurrentPublicHolidays());

async function fetchCurrentPublicHolidays() {
  await store.dispatch("fetchPublicHolidays", { month: currentRange.month, year: currentRange.year });
  currentPublicHolidays.value = publicHolidays.value.get(currentRange.month + currentRange.year);
}

function onMonthChange(evt: CalendarMonthChangeEvent) {
  currentPublicHolidays.value = [];
  currentRange.year = evt.year.toString();
  currentRange.month = padNumberWithZero(evt.month);
  fetchCurrentPublicHolidays();
}
</script>

<style scoped>
label {
  font-weight: bold;
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}

.calendarPublicHoliday {
  color: var(--lightpink);
}

.calendarWeekend {
  color: var(--calendar-weekend);
}
</style>
