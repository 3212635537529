<template>
  <div class="extWorkBox">
    <div class="extWorkHeader">
      <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
      <div v-if="!isLoading">
        <span class="caption">Berufserfahrung vor pep.digital</span>
      </div>
      <div v-if="!isLoading">
        <Button v-if="!noEntries && openExternalWorkPanel" style="margin-right: 1rem" icon="pi pi-plus" @click="toggleEmptyDialog" />
        <Button :icon="getButtonIcon()" @click="toggleExternalWorkPanel" />
      </div>
    </div>
    <div v-if="openExternalWorkPanel && !isLoading">
      <div v-for="(entry, index) in extWorkHistoryData" :key="index" class="extWorkContainer">
        <div class="info">
          <span v-if="noEntries">Keine Daten</span>
          <span v-if="!noEntries" class="text">{{ entry.company_name }}</span>

          <span v-if="!noEntries" class="text">{{ sanatizeHTML(entry.project_content) }}</span>
          <span v-if="!noEntries" class="text">{{ sanatizeHTML(entry.keywords) }}</span>
        </div>
        <div class="card flex justify-content-center buttons">
          <Button v-if="!noEntries" class="editButton" label="Bearbeiten" icon="pi pi-pencil" @click="toggleDialog(entry)" />
          <Button
            v-if="!noEntries"
            label="Löschen"
            icon="pi pi-trash"
            @click="
              () => {
                openDeleteValidation = true;
                setEntryToDelete(entry);
              }
            "
          />
          <Button v-if="noEntries" label="Anlegen" icon="pi pi-plus" @click="toggleEmptyDialog" />
        </div>
        <base-pop-up v-if="openDeleteValidation" :pop-up-message="deleteValidationMessage">
          <Button label="Ja!" style="margin-right: 1rem" @click="deleteExternalWorkHistory" />
          <Button label="Nein!" @click="openDeleteValidation = false" />
        </base-pop-up>
      </div>
      <external-work-history-dialog v-if="isOpen" :external-work-history="entryData" @data-changed="getExternalWorkHistory" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, provide, ref } from "vue";
import { dialog } from "@/keys";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { erpNextProfileExternalWorkHistory } from "@/data-types";
import { useToast } from "primevue/usetoast";
import ExternalWorkHistoryDialog from "./ExternalWorkHistoryDialog.vue";
import BasePopUp from "@/ui/BasePopUp.vue";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService.ts";

const toast = useToast();
const isLoading = ref(true);
const externalWorkHistory = ref<erpNextProfileExternalWorkHistory[]>();
const noEntries = ref(false);
const isOpen = ref(false);
provide(dialog, isOpen);
const extWorkHistoryData = ref();
const openExternalWorkPanel = ref(false);
const openDeleteValidation = ref(false);
const deleteValidationMessage = "Bist du sicher dass du das löschen willst?";
const width = window.innerWidth;
const isMobile = ref(false);
const entryData = ref();
const entryToDelete = ref();

function toggleExternalWorkPanel() {
  openExternalWorkPanel.value = !openExternalWorkPanel.value;
}

function toggleDialog(entry: any) {
  entryData.value = entry;
  isOpen.value = !isOpen.value;
}

function sanatizeHTML(content: string) {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(content, "text/html");
  return parsedHtml.body.textContent || "";
}

function toggleEmptyDialog() {
  entryData.value = {
    name: "",
    designation: "",
    project_content: "",
    tasks: "",
    keywords: "",
    total_experience: "",
    end_date: undefined,
    team_size: "",
    customer_category: "",
    company_name: "",
  };
  isOpen.value = !isOpen.value;
}

function setEntryToDelete(entry: any) {
  entryToDelete.value = entry;
}

onMounted(() => {
  if (width < 1400) isMobile.value = true;
  getExternalWorkHistory();
});

function getButtonIcon() {
  return openExternalWorkPanel.value ? "pi pi-chevron-up" : "pi pi-chevron-down";
}

async function getExternalWorkHistory() {
  isLoading.value = true;
  await erpnextApi.getProfileExternalWorkHistory().then((res) => {
    externalWorkHistory.value = res;
    externalWorkHistory.value.sort((a: any, b: any) => {
      a.end_date = new Date(a.end_date);
      b.end_date = new Date(b.end_date);
      return a.end_date.getTime() - b.end_date.getTime();
    });
    if (externalWorkHistory.value.length === 0) {
      noEntries.value = true;
      isLoading.value = false;
      extWorkHistoryData.value = [
        {
          designation: "",
          project_content: "",
          tasks: "",
          keywords: "",
          total_experience: "",
          end_date: "",
          team_size: "",
          customer_category: "",
          company_name: "",
        },
      ];
    } else {
      extWorkHistoryData.value = externalWorkHistory.value;
      noEntries.value = false;
    }
    isLoading.value = false;
  });
}

async function deleteExternalWorkHistory() {
  isLoading.value = true;
  openDeleteValidation.value = false;
  if (externalWorkHistory.value)
    await erpnextApi
      .deleteExternalWorkHistory(entryToDelete.value.name)
      .then(() => {
        showSuccessToast(toast, "Vorherige Berufserfahrung erfolgreich gelöscht");
        getExternalWorkHistory();
        noEntries.value = true;
      })
      .catch((err: Error) => showErrorToast(toast, err.message))
      .finally(() => (isLoading.value = false));
}
</script>

<style scoped>
.extWorkContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--greystep);
  background-color: var(--greystep);
  border-radius: 5px;
  margin-top: 0.5rem;
}

.text {
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--white);
  border-bottom: 1px solid var(--grey);
}

.editButton {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.info {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--grey);
}

.extWorkBox {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #969696;
  border-radius: 3px;
  margin-left: 3px;
}

.extWorkHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.caption {
  font-size: 18px;
}
</style>
