<template>
  <div class="skillBox">
    <div class="skillHeader">
      <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
      <div v-if="!isLoading">
        <span v-if="!isLoading" class="caption">Skills</span>
      </div>
      <div v-if="!isLoading">
        <Button v-if="!noEntries && openSkillPanel" style="margin-right: 1rem" icon="pi pi-plus" @click="toggleEmptyDialog" />
        <Button :icon="getButtonIcon()" @click="toggleSkillPanel" />
      </div>
    </div>

    <div v-if="openSkillPanel && !isLoading">
      <div v-for="(entry, index) in profileSkillData" :key="index" class="skillContainer">
        <div class="info">
          <span v-if="noEntries">Keine Daten</span>
          <span v-if="!noEntries" class="text">{{ formatDateStringToGerman(entry.evaluation_date) }}</span>
          <div v-if="(entry.proficiency.proficiency >= 1 || entry.proficiency >= 1) && !noEntries" class="proficiencyClass">
            <span v-if="!noEntries && entry.skill.name" class="skillText">{{ entry.skill.name }}</span>
            <span v-else class="skillText">{{ entry.skill }}</span>
            <div v-if="entry.proficiency.proficiency" style="display: flex; flex-direction: row">
              <div v-for="proficiency in entry.proficiency.proficiency" :key="proficiency">
                <i v-if="!noEntries" class="pi pi-star-fill stars" style="color: var(--primary)" />
              </div>
            </div>
            <div v-if="!entry.proficiency.proficiency && !noEntries" style="display: flex; flex-direction: row">
              <div v-for="proficiency in entry.proficiency" :key="proficiency">
                <i v-if="!noEntries" class="pi pi-star-fill stars" style="color: var(--primary)" />
              </div>
            </div>
          </div>
          <div v-if="(entry.proficiency === 0 || entry.proficiency.proficiency === 0) && !noEntries">
            <span v-if="entry.skill.name" class="skillText">{{ entry.skill.name }}</span>
            <span v-else class="skillText">{{ entry.skill }}</span>
            <i v-if="!noEntries" class="pi pi-star-fill stars" />
          </div>
        </div>
        <div class="card flex justify-content-center buttons">
          <Button v-if="!noEntries" class="editButton" label="Bearbeiten" icon="pi pi-pencil" @click="toggleDialog(entry)" />
          <Button v-if="!noEntries" label="Löschen" icon="pi pi-trash" @click="((openDeleteValidation = true), setEntryToDelete(entry))" />
          <Button v-if="noEntries" label="Anlegen" icon="pi pi-plus" @click="toggleEmptyDialog" />
        </div>
        <base-pop-up v-if="openDeleteValidation" :pop-up-message="deleteValidationMessage">
          <Button label="Ja!" style="margin-right: 1rem" @click="deleteProfileSkill" />
          <Button label="Nein!" @click="openDeleteValidation = false" />
        </base-pop-up>
      </div>
      <skill-dialog v-if="isOpen" :skill-data="entryData" :skill-list="skillList" @data-changed="getProfileSkill" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, provide, ref } from "vue";
import { dialog } from "@/keys";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { erpNextProfileSkill } from "@/data-types";
import { formatDateStringToGerman } from "@/utils/Helper";
import { useToast } from "primevue/usetoast";
import BasePopUp from "@/ui/BasePopUp.vue";
import SkillDialog from "./SkillDialog.vue";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService.ts";

const toast = useToast();
const isLoading = ref(true);
const profileSkill = ref<erpNextProfileSkill[]>();
const noEntries = ref(false);
const isOpen = ref(false);
provide(dialog, isOpen);
const profileSkillData = ref();
const openSkillPanel = ref(false);
const openDeleteValidation = ref(false);
const deleteValidationMessage = "Bist du sicher dass du das löschen willst?";
const width = window.innerWidth;
const isMobile = ref(false);
const entryData = ref();
const entryToDelete = ref();
const skillList = ref();

function toggleSkillPanel() {
  openSkillPanel.value = !openSkillPanel.value;
}

function toggleDialog(entry: any) {
  entryData.value = entry;
  isOpen.value = !isOpen.value;
}

function toggleEmptyDialog() {
  entryData.value = {
    name: "",
    skill: "",
    proficiency: "",
    evaluation_date: "",
  };
  isOpen.value = !isOpen.value;
}

function setEntryToDelete(entry: any) {
  entryToDelete.value = entry;
}

onMounted(() => {
  if (width < 1400) {
    isMobile.value = true;
  }
  getProfileSkill();
  getAvailableSkills();
});

function getButtonIcon() {
  const icon = openSkillPanel.value ? "pi pi-chevron-up" : "pi pi-chevron-down";
  return icon;
}

async function getAvailableSkills() {
  isLoading.value = true;
  await erpnextApi.getAvailableSkills().then((res) => {
    skillList.value = res;
    isLoading.value = false;
  });
}

async function getProfileSkill() {
  isLoading.value = true;
  await erpnextApi.getProfileSkill().then((res) => {
    profileSkill.value = res;
    profileSkill.value.sort((a: any, b: any) => {
      return a.evaluation_date - b.evaluation_date;
    });
    if (profileSkill.value.length === 0) {
      noEntries.value = true;
      isLoading.value = false;
      profileSkillData.value = [
        {
          name: "",
          skill: "",
          proficiency: 0,
          evaluation_date: "",
        },
      ];
    } else {
      profileSkillData.value = profileSkill.value;
      noEntries.value = false;
    }
    isLoading.value = false;
  });
}

async function deleteProfileSkill() {
  isLoading.value = true;
  openDeleteValidation.value = false;
  if (profileSkill.value)
    await erpnextApi
      .deleteProfileSkill(entryToDelete.value.name)
      .then(() => {
        showSuccessToast(toast, "Ausbildung erfolgreich gelöscht");
        getProfileSkill();
        noEntries.value = true;
      })
      .catch((err: Error) => showErrorToast(toast, err.message));
}
</script>

<style scoped>
.skillContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--greystep);
  background-color: var(--greystep);
  border-radius: 5px;
  margin-top: 0.5rem;
}

.text {
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--white);
  border-bottom: 1px solid var(--grey);
}

.editButton {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.info {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--grey);
}

.info span {
}

.skillBox {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #969696;
  border-radius: 3px;
  margin-left: 3px;
}

.skillHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.caption {
  font-size: 18px;
}

.proficiencyClass {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stars {
  padding: 0.2rem;
  font-size: 20px;
}

.skillText {
  font-size: 20px;
  margin-right: 0.5rem;
}
</style>
