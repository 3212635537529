import moment from "moment";
import { HolidayRequest } from "@/data-types.ts";

export function calculateDurationBetweenDates(begin: Date, end = begin): number {
  return Math.abs(moment(begin).diff(moment(end), "days")) + 1;
}

export function areDatesCrossingYearBoundary(startDate: Date, endDate: Date): boolean {
  return startDate.getFullYear() !== endDate.getFullYear();
}

function getLastDateOfGivenYear(year: number): Date {
  return new Date(year, 11, 31);
}

function getFirstDateOfGivenYear(year: number): Date {
  return new Date(year, 0, 1);
}

export function isDateRangeValid(dateRange: (Date | undefined)[]): boolean {
  return dateRange.length > 0 && dateRange.every((date) => date !== undefined);
}

function getDateRangeDates(dateRange: (Date | undefined)[]): { startDate: Date; endDate: Date } {
  const startDate = dateRange[0] ?? new Date();
  const endDate = new Date(dateRange[1] ?? startDate);
  return { startDate, endDate };
}

export async function handleAbsenceSubmission(
  dateRange: (Date | undefined)[],
  submitCallback: (startDate: Date, endDate: Date) => Promise<void>,
): Promise<void> {
  const { startDate, endDate } = getDateRangeDates(dateRange);
  const crossesYearBoundary = areDatesCrossingYearBoundary(startDate, endDate);
  if (crossesYearBoundary) await submitHolidayRequestsAcrossYearBoundary(startDate, endDate, submitCallback);
  else await submitCallback(startDate, endDate);
}

async function submitHolidayRequestsAcrossYearBoundary(
  startDate: Date,
  endDate: Date,
  submitCallback: (startDate: Date, endDate: Date) => Promise<void>,
): Promise<void> {
  const endOfYear = getLastDateOfGivenYear(startDate.getFullYear());
  const startOfNextYear = getFirstDateOfGivenYear(endDate.getFullYear());

  await submitCallback(startDate, endOfYear);
  await submitCallback(startOfNextYear, endDate);
}

export function sortAbsencesByDateAsc(absences: HolidayRequest[]): HolidayRequest[] {
  return absences.sort((absenceA, absenceB) => new Date(absenceA.from_date).valueOf() - new Date(absenceB.from_date).valueOf());
}

export function sortAbsencesByDateDesc(absences: HolidayRequest[]): HolidayRequest[] {
  return absences.sort((absenceA, absenceB) => new Date(absenceB.from_date).valueOf() - new Date(absenceA.from_date).valueOf());
}

export function getAbsencesBeforeToday(absences: HolidayRequest[]): HolidayRequest[] {
  return absences.filter((absence) => new Date(absence.to_date).valueOf() < Date.now().valueOf());
}

export function getAbsencesOfTodayAndAfter(absences: HolidayRequest[]): HolidayRequest[] {
  return absences.filter((absence) => new Date(absence.to_date).valueOf() >= Date.now().valueOf());
}
