<template>
  <SideBySideCard class="p-d-flex">
    <template #left>
      <AbsenceTable :new-requests-submitted="newRequestSubmitted" @holiday-deleted="submittedRequest" />
    </template>
    <template #right>
      <AbsenceBooking @submitted="submittedRequest" />
    </template>
  </SideBySideCard>
</template>

<script setup lang="ts">
import { ref } from "vue";
import SideBySideCard from "@/ui/SideBySideCard.vue";
import AbsenceTable from "@/features/absence/components/AbsenceTable.vue";
import AbsenceBooking from "@/features/absence/components/AbsenceBooking.vue";

const newRequestSubmitted = ref(false);

function submittedRequest() {
  newRequestSubmitted.value = !newRequestSubmitted.value;
}
</script>
