<template>
  <BaseSideCardWeekly>
    <div class="p-text-bold" style="color: var(--white); padding-bottom: 8px">Verbleibendes Budget</div>
    <overview-data-entry v-for="(item, index) in totalProjectWork" :key="index" :title="item.name" :value="item.leftHours" :is-sub-item="item.isSubItem" />
    <span style="font-size: 7pt">Basierend auf dem Gesamtbudget aller Projektmitarbeiter</span>
  </BaseSideCardWeekly>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { buildTimeStringFromHours } from "@/utils/Helper";
import OverviewDataEntry from "@/ui/OverviewDataEntry.vue";
import BaseSideCardWeekly from "@/ui/BaseSideCardWeekly.vue";
import { BookingDay, BookingPositionDescription } from "@/data-types.ts";

export interface BPEntry {
  description: string;
  leftHours: number;
}

export interface PositionItem {
  isSubItem: boolean;
  name: string;
  leftHours: string;
}

interface Props {
  bookingPositions: Map<string, BookingPositionDescription>;
  days: BookingDay[];
}

const props = withDefaults(defineProps<Props>(), {
  bookingPositions: () => new Map<string, BookingPositionDescription>(),
});

const totalProjectWork = computed(() => {
  const projects = new Map<string, Map<string, BPEntry>>();
  const items: PositionItem[] = [];

  for (const entry of props.days) {
    for (const ph of entry.positionHours) {
      const bookingPosition = props.bookingPositions.get(ph.name);
      if (bookingPosition && bookingPosition.left_hours) {
        if (!projects.has(bookingPosition.project)) {
          projects.set(bookingPosition.project, new Map<string, BPEntry>());
        }
        const project = projects.get(bookingPosition.project);
        if (!project?.has(ph.name)) {
          project?.set(ph.name, {
            description: bookingPosition.description,
            leftHours: bookingPosition.left_hours,
          });
        }
      }
    }
  }

  for (const [projectName, project] of projects) {
    items.push({
      name: projectName,
      isSubItem: false,
      leftHours: "",
    });

    for (const bp of project.values()) {
      items.push({
        name: bp.description,
        isSubItem: true,
        leftHours: buildTimeStringFromHours(bp.leftHours),
      });
    }
  }
  return items;
});
</script>
