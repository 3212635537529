<template>
  <div class="p-d-flex p-flex-wrap p-ai-center p-mt-3">
    <WorkplaceDate :booking="booking" />
    <WorkplacePublicHoliday v-if="publicHoliday" :public-holiday="publicHoliday.name" class="p-mr-2" />
    <WorkplaceComment :current-booking="booking" :day-disabled="dayDisabled" />
  </div>
  <div style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap">
    <SelectButton
      v-model="currentBooking.workplace"
      :disabled="dayDisabled"
      :options="workplaces"
      :unselectable="false"
      class="workplace-select"
      option-label="name"
      option-value="code"
      @change="onWorkplaceChange"
    />
    <Dropdown
      v-if="currentBooking.workplace === 'office' && !isExternal"
      v-model="currentBooking.transportation_profile"
      :disabled="dayDisabled"
      :options="transportations"
      :unselectable="false"
      class="transportation-select"
      option-label="title"
      option-value="name"
      panel-class="transportation-dropdown"
      @change="$emit('transportationChange', currentBooking)"
    >
      <template #value="slotProps">
        <span style="font-size: 28px">{{ transportations.find((x) => x.name === slotProps.value)?.icon ?? "⁉️" }}</span>
      </template>

      <template #option="slotProps">
        <span style="font-size: 28px">{{ slotProps.option.icon }}</span>
        <div>{{ slotProps.option.title }}</div>
      </template>
    </Dropdown>
    <Dropdown
      v-if="!dayDisabled && transportations?.find((x) => x.name === currentBooking.transportation_profile)?.primary_vehicle?.endsWith('car')"
      v-model="currentBooking.parking_lot_desired"
      :disabled="dayDisabled"
      :options="parkingOptions"
      :unselectable="false"
      class="transportation-select parking-lot-select"
      option-label="title"
      option-value="name"
      panel-class="transportation-dropdown"
      @change="$emit('parkingLotChange', currentBooking)"
    >
      <template #value="slotProps">
        <TooltipWrapper :content="parkingLotStateText()">
          <span style="font-size: 28px">🅿️</span>
          <span v-if="slotProps.value && currentBooking.has_parking_lot" style="position: absolute; right: -5px; bottom: 0; font-size: 18px">🟢</span>
          <span
            v-if="slotProps.value && !currentBooking.has_parking_lot && currentBooking.parking_lot_available"
            style="position: absolute; right: -5px; bottom: 0; font-size: 18px"
            >⏳</span
          >
          <span
            v-if="!slotProps.value || (!currentBooking.parking_lot_available && !currentBooking.has_parking_lot)"
            style="position: absolute; right: -5px; bottom: 0; font-size: 18px"
            >⛔</span
          >
        </TooltipWrapper>
      </template>
    </Dropdown>

    <slot />
    <span v-if="booking.absence_reason" :class="{ 'p-disabled': dayDisabled }" style="padding-left: 10px; align-self: center; font-weight: bold">
      {{ booking.absence_reason }}
    </span>
  </div>
  <p :class="{ 'p-disabled': dayDisabled }">
    {{ amountInOffice }}
  </p>
  <ProgressBar
    :class="getProgressBarClass(dayDisabled, booking.blocked_desks)"
    :show-value="false"
    :value="(booking.blocked_desks * 100) / desks.size"
    class="blocked-bar"
  />

  <DialogAbsenceBooking
    v-model:dialog-visibility="isDialogAbsenceBookingVisible"
    :start-absence="props.booking.date"
    :end-absence="props.booking.date"
    datatest-id="holiday-form-dialog"
    @on-close-without-submit="onCloseWithoutSubmit"
  />

  <Dialog
    v-model:visible="isMissingTransportationDialogVisible"
    :closable="false"
    :dismissable-mask="false"
    :modal="true"
    :style="{ minWidth: '20vw', maxWidth: '400px' }"
    header="Kein Verkehrsmittel festgelegt"
  >
    <div>
      <span class="color-white"
        >Der Arbeitsort <span style="color: darkorange">Büro</span> kann erst ausgewählt werden, wenn ein Standard Verkehrsmittel festgelegt wurde.</span
      >
    </div>
    <div>
      <Button class="p-mt-4" icon="pi pi-arrow-right" label="Zum Profil" style="float: right" @click="router.replace('/profile/transportation')" />
    </div>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, watch } from "vue";
import { CalendarPublicHoliday, Desk, erpNextTransportationProfile, WorkplaceBooking, workplaces } from "@/data-types";
import WorkplaceDate from "@/features/workspace/components/WorkplaceDate.vue";
import SelectButton from "primevue/selectbutton";
import ProgressBar from "primevue/progressbar";
import Button from "primevue/button";
import WorkplaceComment from "./WorkplaceComment.vue";
import WorkplacePublicHoliday from "./WorkplacePublicHoliday.vue";
import Dropdown from "primevue/dropdown";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Dialog from "primevue/dialog";
import TooltipWrapper from "@/ui/TooltipWrapper.vue";
import { getProgressBarClass, getWorkplaceStatusMessage } from "../utils/Workplace";
import { RootState } from "@/store";
import DialogAbsenceBooking from "@/features/workspace/components/DialogAbsenceBooking/DialogAbsenceBooking.vue";

interface Props {
  dayDisabled: boolean;
  booking: WorkplaceBooking;
  desks: Map<string, Desk>;
  publicHoliday: CalendarPublicHoliday | undefined;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  workplaceChange: [currentBooking: WorkplaceBooking];
  transportationChange: [currentBooking: WorkplaceBooking];
  parkingLotChange: [currentBooking: WorkplaceBooking];
}>();

const store = useStore<RootState>();
const currentBooking = reactive(props.booking);
const isDialogAbsenceBookingVisible = ref(false);

let previousWorkplace: string = props.booking.workplace;

watch(
  () => currentBooking.workplace,
  (newValue, oldValue) => {
    previousWorkplace = oldValue;
  },
);

const isMissingTransportationDialogVisible = ref(false);
const amountInOffice = computed(() => getWorkplaceStatusMessage(props.booking));
const parkingOptions = computed(() =>
  currentBooking.has_parking_lot
    ? [
        { name: false, title: "Parkplatz freigeben" },
        {
          name: true,
          title: "Parkplatz zugewiesen",
        },
      ]
    : [
        { name: false, title: "Parkplatz nicht benötigt" },
        {
          name: true,
          title: "Parkplatz gewünscht",
        },
      ],
);

const transportations = computed<erpNextTransportationProfile[]>(() => store.getters.getTransportationProfiles);
const isExternal = computed<boolean | undefined>(() => store.getters.isExternal);

const router = useRouter();

function isUserAllowedToBookOffice(): boolean {
  return store.getters.getEmployee?.is_external || store.getters.getUserProfile.default_transportation;
}

function onCloseWithoutSubmit(): void {
  currentBooking.workplace = previousWorkplace;
}

async function careOfficeWorkplaceBooking(): Promise<void> {
  if (isUserAllowedToBookOffice()) {
    emit("workplaceChange", currentBooking);
  } else {
    isMissingTransportationDialogVisible.value = true;
  }
}

async function onWorkplaceChange(): Promise<void> {
  if (currentBooking.workplace === "office") {
    await careOfficeWorkplaceBooking();
  } else if (currentBooking.workplace === "absent") {
    isDialogAbsenceBookingVisible.value = true;
  } else {
    emit("workplaceChange", currentBooking);
  }
}

function parkingLotStateText() {
  if (currentBooking.has_parking_lot) return "Dir wurde ein Parkplatz für den Tag zugewiesen";
  if (currentBooking.parking_lot_available && currentBooking.parking_lot_desired) return "Entscheidung über Parkplatz-Anfrage noch offen";
  return "Dir wurde leider kein Parkplatz zugewiesen";
}
</script>

<style>
.yellowBar.p-progressbar .p-progressbar-value {
  background: #ffcc00;
}

.redBar.p-progressbar .p-progressbar-value {
  background: red;
}

.transportation-select {
  margin-left: 16px;
  width: 110px;
}

.transportation-dropdown .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {
  min-height: 0;
  padding: 6px;
}

.transportation-dropdown .p-dropdown-items-wrapper .p-dropdown-items {
  padding: 0;
}

.transportation-select .p-dropdown-trigger {
  display: none;
}

.transportation-select .p-dropdown-label {
  padding: 0;
  margin: 0;
}

.transportation-select.p-dropdown {
  border: none;
  padding: 0;
  margin-left: 8px;
  width: 40px;
}

.transportation-select.p-focus {
  box-shadow: none !important;
}

.workplace-select .p-button {
  width: 104px;
}

.transportation-select.parking-lot-select.p-dropdown {
  margin-left: 0;
}

@media (max-width: 456px) {
  .transportation-select.p-dropdown {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .workplace-select .p-button {
    width: 50%;
  }

  .workplace-select .p-button {
    border: 1px solid #383838 !important;
  }
}
</style>

<style scoped>
.blocked-bar {
  height: 6px;
  margin-bottom: 3px;
  background: var(--tableBackground);
}
</style>
