<template>
  <base-widget>
    <ProgressSpinner v-if="!loadedContent" class="p-d-flex p-jc-center" />
    <div v-if="loadedContent" class="moodContainer">
      <div class="moodCaption">Stimmung</div>
      <img v-if="companyMood && companyMood.mood_seven <= 1.7" src="/src/assets/img/giphy.gif" style="font-size: 3rem; width: 240px" />
      <span v-else style="font-size: 3rem">{{ analyseCompanyMood() }}</span>
      <div class="moodCaption">im Unternehmen</div>
    </div>
  </base-widget>
</template>

<script setup lang="ts">
import BaseWidget from "@/ui/BaseWidget.vue";
import { onMounted, ref } from "vue";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { erpNextCompanyMood } from "@/data-types";
import ProgressSpinner from "primevue/progressspinner";

const companyMood = ref<erpNextCompanyMood>();
const loadedContent = ref(false);
const uniCodeHappy = "\uD83D\uDE00";
const uniCodeSad = "\uD83D\uDE10";
const uniCodeNeutral = " \uD83D\uDE10";
const uniCodeParty = "\uD83E\uDD73";

onMounted(() => {
  analyseCompanyMood();
  getCompaniesMood();
});

async function getCompaniesMood() {
  await erpnextApi.getCompanyMood().then((res) => {
    companyMood.value = res;
    loadedContent.value = true;
  });
}

function analyseCompanyMood() {
  if (companyMood.value) {
    if (companyMood.value.mood_seven <= 1.82) {
      return uniCodeParty;
    } else if (companyMood.value.mood_seven <= 1.95) {
      return uniCodeHappy;
    } else if (companyMood.value.mood_seven <= 2.22) {
      return uniCodeNeutral;
    } else {
      return uniCodeSad;
    }
  }
}
</script>

<style scoped>
.moodContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.moodCaption {
  font-size: 24px;
}
</style>
