<template>
  <BaseCard class="voucher-view-card">
    <ConfirmDialog :group="VoucherType.BYOD" style="width: 50%">
      <template #message="slotProps">
        <div style="display: flex; flex-flow: column nowrap; width: 100%">
          <i :class="slotProps.message.icon" style="font-size: xxx-large; align-self: center"></i>
          <p>
            Alle erstellten Voucher, mit Ausnahme der 12-Stunden gültigen Gästevoucher, sind nur für den eigenen Gebrauch bestimmt. Für alle anderen Fälle
            wendet euch bitte an die IT.
          </p>
          <p>{{ slotProps.message.message }}</p>
        </div>
      </template>
    </ConfirmDialog>
    <ConfirmDialog></ConfirmDialog>
    <div>
      <div class="info-header">WLAN Voucher Generation</div>
      <p class="mb-1">Hier findest du die Möglichkeit, WLAN-Voucher für Gäste, eigene Geräte (BYOD) sowie Kundenrechner zu erstellen.</p>
      <ul class="list-unstyled">
        <li><b class="text-success">Gast:</b> Für Besucher, gültig für <span class="font-italic">max. 12 Stunden</span>.</li>
        <li>
          <b class="text-info">BYOD:</b> Für persönliche Geräte von Mitarbeitern sowie Kunden Hardware, gültig für <span class="font-italic">90 Tage</span>.
        </li>
      </ul>
    </div>
    <div>
      <label style="margin: 0">Voucher Erzeugen:</label>
      <div style="display: flex; flex-wrap: wrap">
        <InputText
          v-model="comment"
          data-testid="input-comment"
          :class="{ 'p-invalid': isCommentInvalid && hasCommentBeenTouched }"
          placeholder="Person oder Gerät"
          class="p-mr-2 p-mt-2"
          type="text"
          variant="filled"
          @blur="validateComment(comment)"
        />
        <Dropdown
          v-model="selectedVoucher"
          data-testid="input-voucher"
          :options="voucher"
          option-label="label"
          option-value="value"
          placeholder="Voucher"
          class="p-mr-2 p-mt-2"
          :class="{ 'p-invalid': isSelectedVoucherInvalid && hasVoucherBeenTouched }"
          @change="validateVoucherType(selectedVoucher)"
        />
        <Button
          :disabled="isVoucherGenerationDisabled"
          class="p-mr-2 p-mt-2 generate-button"
          @click="selectedVoucher === VoucherType.BYOD ? onGenerateVisitorVoucher(VoucherType.BYOD) : onGenerateVisitorVoucher()"
        >
          <div v-if="!isLoading">Generieren</div>
          <ProgressSpinner v-else class="p-d-flex p-jc-center spinner-wrapper generate-button" />
        </Button>
      </div>
    </div>
    <div v-if="voucherCode" class="voucher-result">
      Neuer Voucher wurde erstellt. Kopiere ihn jetzt, du wirst ihn mit verlassen der Seite nicht mehr sehen können!
      {{ voucherText }}

      <InputGroup class="p-mt-1" style="align-self: center; width: auto">
        <InputGroupAddon class="code-display">
          {{ voucherCode }}
        </InputGroupAddon>
        <Button
          v-tooltip="'Voucher in die Zwischenablage kopieren'"
          class="code-copy"
          icon="pi pi-copy"
          outlined
          type="button"
          @click="copyCodeToClipboard()"
        />
      </InputGroup>
    </div>
    <GeneratedVoucherTable :parent-is-loading="isLoading"></GeneratedVoucherTable>
  </BaseCard>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import BaseCard from "@/ui/BaseCard.vue";
import ConfirmDialog from "primevue/confirmdialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { VoucherType, WifiVoucherCreationDto } from "@/data-types.ts";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService.ts";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { ConfirmationOptions } from "primevue/confirmationoptions";
import GeneratedVoucherTable from "@/features/voucher/components/GeneratedVoucherTable.vue";

interface VoucherDropDown {
  label: string;
  value: string;
}

const confirm = useConfirm();
const toast = useToast();

const voucher: VoucherDropDown[] = [
  {
    label: "BYOD",
    value: VoucherType.BYOD,
  },
  {
    label: "Gast",
    value: VoucherType.GUEST,
  },
];

const hasCommentBeenTouched = ref(false);
const hasVoucherBeenTouched = ref(false);

const comment = ref("");
const isCommentInvalid = ref(true);

function validateComment(comment: string) {
  hasCommentBeenTouched.value = true;
  isCommentInvalid.value = comment.trim() === "";
}

const selectedVoucher = ref<VoucherType>();
const isSelectedVoucherInvalid = ref(true);

function validateVoucherType(voucherType: VoucherType | undefined) {
  hasVoucherBeenTouched.value = true;
  isSelectedVoucherInvalid.value = voucherType === undefined;
}

const isVoucherGenerationDisabled = computed(() => {
  return isSelectedVoucherInvalid.value || isCommentInvalid.value || isLoading.value;
});

const voucherCode = ref("");
const voucherText = ref("");

function resetInputs() {
  comment.value = "";
  selectedVoucher.value = undefined;
  isCommentInvalid.value = true;
  isSelectedVoucherInvalid.value = true;
  hasVoucherBeenTouched.value = false;
  hasCommentBeenTouched.value = false;
}

async function generateVoucher() {
  isLoading.value = true;

  const wifiVoucherCreationDto: WifiVoucherCreationDto = {
    voucher_type: selectedVoucher.value!,
    reason: comment.value,
  };
  try {
    voucherCode.value = await erpnextApi.createVoucherCode(wifiVoucherCreationDto);
  } catch (e: any) {
    showErrorToast(toast, e.message);
  } finally {
    resetInputs();
    isLoading.value = false;
  }
}

function copyCodeToClipboard() {
  navigator.clipboard.writeText(voucherCode.value);
  showSuccessToast(toast, `Code "${voucherCode.value}" in die Zwischenablage kopiert`);
}

const isLoading = ref(false);

const confirmDialogContent = (): ConfirmationOptions => ({
  message: "Mit 'JA' wird ein Voucher erstellt, möchtest du das?",
  header: "Bestätigung",
  icon: "pi pi-exclamation-circle",
  rejectClass: "p-button-danger p-button-outlined",
  rejectLabel: "Abbrechen",
  acceptLabel: "Ja",
  accept: async () => {
    toast.add({ severity: "info", summary: "Confirmed", detail: "You have accepted", life: 3000 });
    await generateVoucher();
  },
  reject: () => toast.add({ severity: "error", summary: "Rejected", detail: "You have rejected", life: 3000 }),
});

const onGenerateVisitorVoucher = (group?: VoucherType) => {
  const requireContent = confirmDialogContent();
  requireContent.group = group;
  confirm.require(requireContent);
};
</script>

<style scoped>
.voucher-view-card {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.code-display {
  color: var(--white);
  border-color: var(--borderColor);
}

.code-copy {
  border-color: var(--borderColor);
}

.voucher-result {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 400px;
  margin-top: 1rem;
}

.generate-button {
  width: 110px;
  height: 39px;
}
</style>
