import { ModelRef } from "vue";
import { DialogAbsenceBookingEmit } from "@/features/workspace/components/DialogAbsenceBooking/DialogAbsenceBooking.vue";

export function useDialogAbsenceBooking(isDialogVisible: ModelRef<boolean, string>, emit: DialogAbsenceBookingEmit) {
  let newRequestSubmitted = false;

  function closeDialog(): void {
    isDialogVisible.value = false;
  }

  function submittedRequest(): void {
    newRequestSubmitted = true;
    closeDialog();
  }

  function handleClose() {
    if (!newRequestSubmitted) {
      emit("onCloseWithoutSubmit");
    } else {
      // this method is called every time the dialog is closed
      // in cases of a newRequest was submitted this flag needs to be reset
      newRequestSubmitted = false;
    }
  }

  return {
    submittedRequest,
    handleClose,
  };
}
